import React, { useContext, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import AppContext from '../AppContext';
import AppendScript from '../components/AppendScript';
import Alljson from './AllDetails.json';
import { scrollBottomToTop } from '../components/Function';
import SubscribeSection from '../components/SubscribeSection';
import useGAEventTracker from '../components/useGAEventTracker';

function AboutUs() {
    const history = useHistory();
    const { dispatchUrlChange } = useContext(AppContext);
    
    const AboutPage = Alljson?.data?.aboutUs;
    const GeneralDetails = Alljson?.data?.SiteDetails;

    useEffect(() => {
        dispatchUrlChange('CHNAGE_URLNAME', { data: ['about-us'] });
        document.body.classList.remove('special-cut-page');
        scrollBottomToTop();
    }, [])

    function chkBlank(text) {
        if (text != "") {
            return text;
        } else {
            return "";
        }
    }

    const GAEventsTracker = useGAEventTracker("Links");

    const ContentClickHandler = (e) => {
        const targetLink = e.target.closest('a');
        if (!targetLink) return;
        e.preventDefault();
        if (targetLink.href) {
            const targetBlank = targetLink.target;

            if(targetBlank == "_blank"){
                window.open(targetLink.href, '_blank');
                GAEventsTracker(targetLink.href);
            }else{
                const link = targetLink.href.split("/").pop();
                if (link) {
                    history.push(`/${link}`);
                }
            }
        }
    }

    return (
        <>
            <div className="wrapper">
                {
                    AboutPage?.pageBanner != "" ?
                        <div className="subpage-banner about-banner" style={chkBlank(AboutPage?.pageBanner?.background_image) ? { backgroundImage: `url(${AboutPage?.pageBanner.background_image})` } : {}}>
                            <div className="container container-big">
                                <ul className="breadcrumb slide-top">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li>About</li>
                                </ul>
                            </div>
                            <div className="container">
                                <h1 className="page-name slide-top"><span className="sub-tit">{chkBlank(AboutPage?.pageBanner?.main_title)}</span>{chkBlank(AboutPage?.pageBanner?.sub_title)}</h1>
                            </div>
                        </div>
                        : ""
                }
                {
                    AboutPage?.pageContent?.length > 0 ?
                        <>
                            <div className="clear"></div>
                            <div className="content-main">
                                {
                                    AboutPage.pageContent.map(function (ele, i) {
                                        if (ele?.acf_fc_layout == "two_section_about_page") {
                                            return (
                                                <div className="section-white about-section pdt-0 pdb-0" key={`sec-${i}`}>
                                                    <div className="container container-big">
                                                        <div className="list-main">
                                                            <div className="list left-content">
                                                                <div className="tit1 slide-top" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(ele?.left_section) }}></div>
                                                            </div>
                                                            <div className="list right-content">
                                                                <div className="about-image slide-top">
                                                                    {
                                                                        chkBlank(ele?.background_image) ?
                                                                            <img className="about-desktop" src={ele.background_image} alt="" title="" />
                                                                            : ''
                                                                    }
                                                                    {
                                                                        chkBlank(ele?.mobile_background_image) ?
                                                                            <img className="about-mobile" src={ele.mobile_background_image} alt="" title="" />
                                                                            : ''
                                                                    }
                                                                </div>
                                                                <div className="slide-top about-content" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(ele?.right_section) }}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }else if(ele?.acf_fc_layout == "multiple_list_section_about"){
                                            return(
                                                <div className="section-white statistics-section pdb-0" key={`sec-${i}`}>
                                                    <div className="container container-big">
                                                        <ul className="statistics-list">
                                                            {
                                                                ele?.list?.length > 0 ? 
                                                                    ele.list.map(function(cer,c){
                                                                        return(
                                                                            <li className={`list${Number(c)+1} slide-top`} key={`mList-${c}`}>
                                                                                <h3 className="counter-main"><span className="counter" id='counter'>{chkBlank(cer?.title)}</span>{chkBlank(cer?.plus_sign) == "Yes" ? '+' : ''}</h3>
                                                                                <h4 className="tit">{chkBlank(cer?.sub_title)}</h4>
                                                                            </li>
                                                                        )
                                                                    })
                                                                : ''
                                                            }
                                                            <li className="list3 slide-top">
                                                                <div className="statistics-certified">
                                                                    <div className="certified-list">
                                                                        <h3 className="counter-main">{chkBlank(ele?.main_title)}</h3>
                                                                        <h4 className="tit">{chkBlank(ele?.sub_title)}</h4>
                                                                    </div>
                                                                    <div className="certified-list certified-list2">
                                                                        <div className="list-sub">
                                                                            {
                                                                                chkBlank(ele?.igi_certified_image) ? 
                                                                                <img src={ele.igi_certified_image} alt="" title="" />
                                                                                :''
                                                                            }
                                                                        </div>
                                                                        <div className="list-sub">
                                                                            {
                                                                                chkBlank(ele?.gia_certified_image) ? 
                                                                                <img src={ele.gia_certified_image} alt="" title="" />
                                                                                :''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            )
                                        }else if(ele?.acf_fc_layout == "multiple_image_content_about"){
                                            return(
                                                <div className="section-white why-ethereal-section pdb-0" key={`sec-${i}`}>
                                                    <div className="container container-big">
                                                        <h3 className="title-section slide-top"><span className="sub-title-section">{chkBlank(ele?.main_title)}</span>{ele?.sub_title}</h3>
                                                        {
                                                            ele?.image_section_list?.length > 0 ?
                                                                <>
                                                                    <ul className="why-ethereal-list">
                                                                        {
                                                                            ele?.image_section_list?.map(function(img,im){
                                                                                return(
                                                                                    <li className="slide-top" key={`li-${im}`}>
                                                                                        <div className="list-main">
                                                                                            <div className="list left-content">
                                                                                                {
                                                                                                    chkBlank(img?.section_image) ?
                                                                                                        <img src={img?.section_image} alt="" title="" />
                                                                                                    : ''
                                                                                                }
                                                                                            </div>
                                                                                            <div className="list right-content">
                                                                                                <h5 className="tit">{chkBlank(img.section_title)}</h5>
                                                                                                <div className="desc" onClick={ContentClickHandler} dangerouslySetInnerHTML={{__html : chkBlank(img.section_description)}}></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </>
                                                            : ''
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }else if(ele?.acf_fc_layout == "global_presence_section"){
                                            return(
                                                <div className="section-white global-presence-section mgb" key={`sec-${i}`}>
                                                    <div className="container">
                                                        <div className="list-main">
                                                            <h3 className="title-section text-center slide-top">{chkBlank(ele?.title)}</h3>
                                                            {
                                                                ele?.left_side_list?.length > 0 ? 
                                                                    <>
                                                                        <ul className="list left-list">
                                                                            {
                                                                                ele.left_side_list.map(function(left,l){
                                                                                    return(
                                                                                        <li className="slide-top" key={`gli-${l}`}><a className="btn btn-pink btn-outline" href={`tel:${chkBlank(left?.contact_number)}`}><span className="country-name">{chkBlank(left?.country_name)}</span><span className="phone">{chkBlank(left?.contact_number)}</span></a></li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </>
                                                                : ''
                                                            }
                                                            {
                                                                ele?.right_side_list?.length > 0 ? 
                                                                    <>
                                                                        <ul className="list right-list">
                                                                            {
                                                                                ele.right_side_list.map(function(right,r){
                                                                                    return(
                                                                                        <li className="slide-top" key={`grli-${r}`}><a className="btn btn-pink btn-outline" href={`tel:${chkBlank(right?.contact_number)}`}><span className="country-name">{chkBlank(right?.country_name)}</span><span className="phone">{chkBlank(right?.contact_number)}</span></a></li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </>
                                                                : ''
                                                            }
                                                            <div className="clear"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }else if(ele?.acf_fc_layout == "application_download_section"){
                                            return(
                                                <div className="section-white home-app pdt-0" key={`sec-${i}`}>
                                                    <div className="container">
                                                        <div className="bg">
                                                            <div className="list left-content">
                                                                <div className="tit slide-bottom" onClick={ContentClickHandler} dangerouslySetInnerHTML={{__html : chkBlank(ele?.section_title)}}></div>
                                                                    {
                                                                        GeneralDetails?.application?.length > 0 ?
                                                                            <>
                                                                                <ul className="app-list">
                                                                                    {
                                                                                        GeneralDetails?.application.map(function(app,a){
                                                                                            return(
                                                                                                <li key={`ali-${a}`}>
                                                                                                    <a target="_blank" href={chkBlank(app?.url)} onClick={e => GAEventsTracker("Mobile Application",app.url)}>
                                                                                                        {
                                                                                                            chkBlank(app?.normal_image) ? 
                                                                                                                <img className="app-icon normal" src={app?.normal_image} title="" alt="" />
                                                                                                            : ''
                                                                                                        }
                                                                                                        {
                                                                                                            chkBlank(app?.hover_image) ? 
                                                                                                                <img className="app-icon hover" src={app?.hover_image} title="" alt="" />
                                                                                                            : ''
                                                                                                        }
                                                                                                    </a>
                                                                                                </li>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ul>
                                                                            </>
                                                                        : ''
                                                                    }
                                                            </div>
                                                            <div className="list right-image slide-bottom">
                                                                {
                                                                    chkBlank(ele?.image) ? 
                                                                        <img className="app-img" src={ele?.image} title="" alt="" />
                                                                    : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }

                                <SubscribeSection />
                            </div>
                            {/* <!--/.content-main--> */}
                        </>
                        : ''
                }
                <AppendScript />
            </div>
            {/* <!--/.wrapper--> */}
        </>
    )
}

export default AboutUs