import React, { useContext, useEffect } from 'react'
import AppContext from '../AppContext';
import { NavLink, useHistory } from 'react-router-dom';
import AppendScript from '../components/AppendScript';
import Alljson from './AllDetails.json';
import { scrollBottomToTop } from '../components/Function';
import SubscribeSection from '../components/SubscribeSection';
import useGAEventTracker from '../components/useGAEventTracker';

function Knowledge() {
    const history = useHistory();
    const { dispatchUrlChange } = useContext(AppContext);

    const KnowledgePage = Alljson?.data?.knowledge;
    const GeneralDetails = Alljson?.data?.SiteDetails;

    useEffect(() => {
        dispatchUrlChange('CHNAGE_URLNAME', { data: ['knowledge'] });
        document.body.classList.remove('special-cut-page');
        scrollBottomToTop();
    }, [])

    function chkBlank(text) {
        if (text != "") {
            return text;
        } else {
            return "";
        }
    }

    const GAEventsTracker = useGAEventTracker("Links");

    const ContentClickHandler = (e) => {
        const targetLink = e.target.closest('a');
        if (!targetLink) return;
        e.preventDefault();
        if (targetLink.href) {
            const targetBlank = targetLink.target;

            if(targetBlank == "_blank"){
                window.open(targetLink.href, '_blank');
                GAEventsTracker(targetLink.href);
            }else{
                const link = targetLink.href.split("/").pop();
                if (link) {
                    history.push(`/${link}`);
                }
            }
        }
    }

    return (
        <div className="wrapper">
            {
                KnowledgePage?.pageBanner != "" ?
                    <div className="subpage-banner" style={chkBlank(KnowledgePage?.pageBanner?.background_image) ? { backgroundImage: `url(${KnowledgePage.pageBanner.background_image})` } : {}}>
                        <div className="container container-big">
                            <ul className="breadcrumb slide-top">
                                <li><NavLink to="/">Home</NavLink></li>
                                <li>Knowledge</li>
                            </ul>
                            <h1 className="page-name slide-top">{chkBlank(KnowledgePage?.pageBanner?.main_title)}</h1>
                        </div>
                    </div>
                : ''
            }
            {/* <!--/.home-slider--> */}
            {
                KnowledgePage?.pageContent?.length > 0 ?
                <>
                    <div className="clear"></div>
                    <div className="content-main">
                        {
                            KnowledgePage.pageContent.map(function (ele, i) {
                                if (ele?.acf_fc_layout == "two_section_knowledge") {
                                    return (
                                        <div className="section-white knowledge-section pdt-0" key={`sec-${i}`}>
                                            <div className="container container-big">
                                                <div className="list-main">
                                                    <div className="list left-content">
                                                        <div className="section-title2">
                                                            <h2 className="title slide-top"><span className="sub-title">{chkBlank(ele?.top_section_main_title)}</span> {chkBlank(ele?.top_section_sub_title)}</h2>
                                                            <div className="description slide-top" onClick={ContentClickHandler} dangerouslySetInnerHTML={{__html : chkBlank(ele?.top_section_description)}}></div>
                                                        </div>
                                                        <div className="diamond-quote">
                                                            <h5 className="tit1 slide-top">{chkBlank(ele?.bottom_section_title)}</h5>
                                                            <div className="desc slide-top" onClick={ContentClickHandler} dangerouslySetInnerHTML={{__html : chkBlank(ele?.bottom_section_description)}}></div>
                                                        </div>
                                                    </div>
                                                    <div className="list right-content slide-top">
                                                        {
                                                            chkBlank(ele?.image) ? 
                                                                <img src={ele.image} alt="" title="" />
                                                            : ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }else if(ele?.acf_fc_layout == "tab_section_knowledge"){
                                    return(
                                        <div className="section-white why-lab-grown-diamond-section pdt-0" key={`sec-${i}`}>
                                            <div className="container container-big">
                                                <div className="section-title2 text-center slide-top">
                                                    <h2 className="title"><span className="sub-title">{chkBlank(ele?.main_title)}</span> {chkBlank(ele?.sub_title)}</h2>
                                                    <div className="description" onClick={ContentClickHandler} dangerouslySetInnerHTML={{__html : ele?.title_description}}></div>
                                                </div>
                                                    {
                                                        ele?.tab_list?.length > 0 ?
                                                            <>
                                                            <div className="tabs-style2 slide-top">
                                                                <ul className="nav nav-tabs MenuTabHoverOpen" id="myTab" role="tablist">
                                                                    {
                                                                        ele.tab_list.map(function(tab,t){
                                                                            return(
                                                                                <li className="nav-item" role="presentation" key={`tab-${t}`}>
                                                                                    <button className={`nav-link tab-button ${t == 0 ? 'active' : ''}`} id={`${t}-tab`} data-bs-toggle="tab" data-bs-target={`tab-${t}`} type="button" role="tab" aria-controls="true-diamond" aria-selected="true" data-hover="tab">
                                                                                        <div className="list-main">
                                                                                            <div className="list icon">
                                                                                                {
                                                                                                    chkBlank(tab?.icon) ?
                                                                                                        <img src={tab.icon} title="" alt="" />
                                                                                                    : ''
                                                                                                }
                                                                                            </div>
                                                                                            <div className="list name">{chkBlank(tab?.title)}</div>
                                                                                            <div className="list arrow"><img src="images/icon/arrow-right-white.svg" title="" alt="" /></div>
                                                                                        </div>
                                                                                    </button>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                                <div className="tab-content" id="myTabContent">
                                                                    {
                                                                        ele.tab_list.map(function(tab,t){
                                                                            return(
                                                                                <div className={`tab-pane fade ${t == 0 ? 'show active' : ''}`} id={`tab-${t}`} role="tabpanel" aria-labelledby={`${t}-tab`} key={`tabDesc-${t}`}>
                                                                                    <div className="tit-section">
                                                                                        <div className="list-section left-icon">
                                                                                            {
                                                                                                chkBlank(tab?.image) ?
                                                                                                    <img src={tab.image} title="" alt="" />
                                                                                                : ''
                                                                                            }
                                                                                        </div>
                                                                                        <div className="list-section right-con">
                                                                                            <div className="section-title2 pdb-0">
                                                                                                <h3 className="title">{chkBlank(tab?.title)}</h3>
                                                                                                <div className="description" onClick={ContentClickHandler} dangerouslySetInnerHTML={{__html : chkBlank(tab?.description)}}></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="accordion accordion-style3" id="accordionExample">
                                                                {
                                                                    ele.tab_list.map(function(acco,a){
                                                                        return(
                                                                            <div className="accordion-item slide-bottom" key={`acco-${a}`}>
                                                                                <h2 className="accordion-header" id={`heading${a}`}>
                                                                                    <button className={`accordion-button ${a > 0 ? "collapsed" : ""} `} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${a}`} aria-expanded={a == 0 ? "true" : "false"} aria-controls={`collapse${a}`}>
                                                                                        <div className="list-main">
                                                                                            <div className="list icon">
                                                                                                {
                                                                                                    chkBlank(acco?.icon) ? 
                                                                                                        <img src={acco.icon} title="" alt="" />
                                                                                                    : ''
                                                                                                }
                                                                                            </div>
                                                                                            <div className="list name">{chkBlank(acco?.title)}</div>
                                                                                            <div className="list arrow"><img src="images/icon/arrow-right-white.svg" title="" alt="" /></div>
                                                                                        </div>
                                                                                    </button>
                                                                                </h2>
                                                                                <div id={`collapse${a}`} className={`accordion-collapse collapse ${a == 0 ? 'show' : '' }`} aria-labelledby={`heading${a}`} data-bs-parent="#accordionExample">
                                                                                    <div className="accordion-body">
                                                                                        <div className="tit-section">
                                                                                            <div className="list-section left-icon">
                                                                                                {
                                                                                                    chkBlank(acco?.image) ? 
                                                                                                        <img src={acco.image} title="" alt="" />
                                                                                                    : ''
                                                                                                }
                                                                                            </div>
                                                                                            <div className="list-section right-con">
                                                                                                <div className="section-title2 pdb-0">
                                                                                                    <h3 className="title">{chkBlank(acco?.title)}</h3>
                                                                                                    <div className="description" onClick={ContentClickHandler} dangerouslySetInnerHTML={{__html : chkBlank(acco?.description)}}></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            </>
                                                        : ''
                                                    }
                                            </div>
                                        </div>
                                    )
                                }else if(ele?.acf_fc_layout == "about_type_section_knowledge"){
                                    return(
                                        <div className="section-white about-iia-diamond-section" key={`sec-${i}`}>
                                            <div className="container container-big">
                                                <div className="section-title2 text-center slide-top">
                                                    <h2 className="title"><span className="sub-title">{chkBlank(ele?.main_title)}</span> {chkBlank(ele?.sub_title)}</h2>
                                                    <div className="description" onClick={ContentClickHandler} dangerouslySetInnerHTML={{__html : chkBlank(ele?.title_description)}}></div>
                                                </div>
                                                <div className="list-main">
                                                    <div className="list left-content slide-top">
                                                        <p onClick={ContentClickHandler} dangerouslySetInnerHTML={{__html : chkBlank(ele?.left_section)}}></p>
                                                        <p className="text-md2">{chkBlank(ele?.left_section_list_title)}</p>
                                                        {
                                                            ele?.left_section_list?.length > 0 ?
                                                                ele.left_section_list.map(function(left,l){
                                                                    return(
                                                                        <React.Fragment key={`leftList-${l}`}>
                                                                            <p onClick={ContentClickHandler} dangerouslySetInnerHTML={{__html : chkBlank(left?.top_description)}} key={`llist-p${l}`}></p>
                                                                            <div className="type-box slide-top" key={`llist-div${l}`}>
                                                                                <div className="type-box-list-main">
                                                                                    <div className="type-box-list type-box-left" onClick={ContentClickHandler} dangerouslySetInnerHTML={{__html : chkBlank(left?.description)}}>
                                                                                    </div>
                                                                                    <div className="type-box-list type-box-right">
                                                                                        {
                                                                                            chkBlank(left?.image) ? 
                                                                                                <img src={left.image} alt="" title="" />
                                                                                            : ''
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            : ''
                                                        }
                                                    </div>
                                                    <div className="list right-content slide-top">
                                                        <p onClick={ContentClickHandler} dangerouslySetInnerHTML={{__html : chkBlank(ele?.right_section)}}></p>
                                                        <p className="text-md2">{chkBlank(ele?.right_section_list_title)}</p>
                                                        {
                                                            ele?.right_section_list?.length > 0 ?
                                                                ele.right_section_list.map(function(right,r){
                                                                    return(
                                                                        <React.Fragment key={`rightList-${r}`}>
                                                                            <p onClick={ContentClickHandler} dangerouslySetInnerHTML={{__html : chkBlank(right?.top_description)}} key={`rlist-p${r}`}></p>
                                                                            <div className="type-box slide-top" key={`rlist-div${r}`}>
                                                                                <div className="type-box-list-main">
                                                                                    <div className="type-box-list type-box-left" onClick={ContentClickHandler} dangerouslySetInnerHTML={{__html : chkBlank(right?.description)}}>
                                                                                    </div>
                                                                                    <div className="type-box-list type-box-right">
                                                                                        {
                                                                                            chkBlank(right?.image) ? 
                                                                                                <img src={right.image} alt="" title="" />
                                                                                            : ''
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            : ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }else if(ele?.acf_fc_layout == "table_section_knowledge"){
                                    return(
                                        <div className="section-white mined-lab-grown-diamonds-section" key={`sec-${i}`}>
                                            <div className="container container-big">
                                                <div className="section-title2 text-center slide-top pdb-0"><h2 className="title"><span className="sub-title">{chkBlank(ele?.main_title)}</span> {chkBlank(ele?.sub_title)}</h2></div>
                                                <div className="responsive-table-main slide-top">
                                                    <table className="responsive-table" cellPadding="0" cellSpacing="0">
                                                        <thead>
                                                            <tr>
                                                                <th>&nbsp;</th>
                                                                <th className="text-center">{chkBlank(ele?.table_heading_1)}</th>
                                                                <th className="text-center">{chkBlank(ele?.table_heading_2)}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                ele?.details_list?.length > 0 ?
                                                                    ele?.details_list.map(function(tbl,t){
                                                                        return(
                                                                            <tr key={`tr-${t}`}>
                                                                                <td className="pdl-0">{chkBlank(tbl?.column_1)}</td>
                                                                                <td className="text-center">{chkBlank(tbl?.column_2)}</td>
                                                                                <td className="text-center">{chkBlank(tbl?.column_3)}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                : ''
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                        <SubscribeSection />

                        <div id="parallax" className="section-white follow-our-journey-section mgb">
                            <div className="container">
                                <div className="caption">
                                    <div className="caption-inn slide-top">
                                        <h2 className="title-section text-white">Follow our journey</h2>
                                        {
                                            chkBlank(GeneralDetails?.instagram_link) ?
                                                <a className="btn btn-white" target="_blank" href={GeneralDetails?.instagram_link} onClick={e => GAEventsTracker("Social Media",GeneralDetails.instagram_link)}>{chkBlank(GeneralDetails.instagram_button_label)}</a>
                                            : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : ""
            }
            {/* <!--/.content-main--> */}
            <AppendScript />
        </div>
    )
}

export default Knowledge