import React, { useContext, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom';
import AppContext from '../AppContext';
import AppendScript from '../components/AppendScript';
import Alljson from './AllDetails.json';
import { scrollBottomToTop } from '../components/Function';
import SubscribeSection from '../components/SubscribeSection';
import useGAEventTracker from '../components/useGAEventTracker';

function Process() {
    const history = useHistory();
    const { dispatchUrlChange } = useContext(AppContext);

    const ProcessPage = Alljson?.data?.process;
    const GeneralDetails = Alljson?.data?.SiteDetails;

    useEffect(() => {
        dispatchUrlChange('CHNAGE_URLNAME', { data: ['process'] });
        document.body.classList.remove('special-cut-page');
        scrollBottomToTop();
    }, [])

    function chkBlank(text) {
        if (text != "") {
            return text;
        } else {
            return "";
        }
    }

    const GAEventsTracker = useGAEventTracker("Links");

    const ContentClickHandler = (e) => {
        const targetLink = e.target.closest('a');
        if (!targetLink) return;
        e.preventDefault();
        if (targetLink.href) {
            const targetBlank = targetLink.target;

            if(targetBlank == "_blank"){
                window.open(targetLink.href, '_blank');
                GAEventsTracker(targetLink.href);
            }else{
                const link = targetLink.href.split("/").pop();
                if (link) {
                    history.push(`/${link}`);
                }
            }
        }
    }

    return (
        <>
            <div className="wrapper">
                {
                    ProcessPage?.pageBanner != "" ?
                        <div className="subpage-banner" style={chkBlank(ProcessPage?.pageBanner?.background_image) ? { backgroundImage: `url(${ProcessPage?.pageBanner.background_image})` } : {}}>
                            <div className="container container-big">
                                <ul className="breadcrumb slide-top">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li>Process</li>
                                </ul>
                            </div>
                            <div className="container">
                                <h1 className="page-name slide-top">{chkBlank(ProcessPage?.pageBanner?.main_title)}</h1>
                                <div className="page-name-desc slide-top">{chkBlank(ProcessPage?.pageBanner?.sub_title)}</div>
                            </div>
                        </div>
                        : ""
                }
                {/* <!--/.home-slider--> */}
                {
                    ProcessPage?.pageContent?.length > 0 ?
                        <>
                            <div className="clear"></div>
                            <div className="content-main">
                                {
                                    ProcessPage.pageContent.map(function (ele, i) {
                                        if (ele?.acf_fc_layout == "process_page_section") {
                                            return (
                                                <div className="section-white process-section pdt-0 pdb-0" key={`sec-${i}`} onClick={ContentClickHandler}>
                                                    <div className="container container-big">
                                                        {
                                                            ele?.process_list?.length > 0 ?
                                                                <>
                                                                    <ul className="process-list">
                                                                        {
                                                                            ele.process_list.map(function (list, l) {
                                                                                return (
                                                                                    <li className={`slide-top process${Number(l) + 1}`} key={l}>
                                                                                        <div className="bg-line"></div>
                                                                                        <div className="process-top-main">
                                                                                            {
                                                                                                chkBlank(list?.image) ?
                                                                                                    <img className="process-icon" src={list.image} alt="" title="" />
                                                                                                    : ''
                                                                                            }
                                                                                            <div className="process-top-text">
                                                                                                <div className="list left-num">{chkBlank(list?.title)}</div>
                                                                                                <div className="list right-content" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(list?.description) }}></div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="process-img">
                                                                                            {
                                                                                                chkBlank(list?.background_image) ?
                                                                                                    <img src={list.background_image} alt="" title="" />
                                                                                                    : ''
                                                                                            }
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ''
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                                <SubscribeSection />
                                <div id="parallax" className="section-white follow-our-journey-section mgb">
                                    <div className="container">
                                        <div className="caption">
                                            <div className="caption-inn slide-top">
                                                <h2 className="title-section text-white">Follow our journey</h2>
                                                {
                                                    chkBlank(GeneralDetails?.instagram_link) ?
                                                        <a className="btn btn-white" target="_blank" href={GeneralDetails?.instagram_link} onClick={e => GAEventsTracker("Social Media",GeneralDetails.instagram_link)}>{chkBlank(GeneralDetails.instagram_button_label)}</a>
                                                        : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        : ''
                }
                {/* <!--/.content-main--> */}
                <AppendScript />
            </div>
        </>
    )
}

export default Process