import React from 'react'
import { useEffect } from 'react';
import AppContext from '../AppContext';
import { useContext } from 'react';
import AppendScript from '../components/AppendScript';

function Inventory() {
  const { dispatchUrlChange } = useContext(AppContext);

  useEffect(() => {
    dispatchUrlChange('CHNAGE_URLNAME', { data: ['inventory'] });
    document.body.classList.remove('special-cut-page');
  },[])
  
  return (
    <>
        <div className="wrapper">
            <AppendScript />
        </div>
    </>
  )
}

export default Inventory