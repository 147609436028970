import './App.css';
import Routes from './routes/Routes';
import { useHistory, useRouteMatch, withRouter } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AppContextPageName from './AppContext';
import Header from './components/Header';
import Footer from './components/Footer';
import ReactGA from 'react-ga';
import Alljson from '../src/pages/AllDetails.json';
import Helmet from 'react-helmet';

function App() {
  const history = useHistory();

  const [getCurrentPage, setCurrentPage] = useState([]);
  const dispatchUrlChange = (actionType, payload) => {
    switch (actionType) {
      case 'CHNAGE_URLNAME':
        setCurrentPage(payload.data);
        return;
      default:
        return;
    }
  };

  const match = useRouteMatch(['/home', '/about-us','/process','/knowledge','/special-cut','/faq','/privacy-policy','/terms-and-conditions','/contact-us']);
  useEffect(() => {
    const slugName = window.location.pathname.split("/").pop();
    if(slugName != ""){
      history.push(`/${slugName}`);
    }else{
      history.push("/");
    }

    if (!match) {
      history.push("/");
    }
  }, [])

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  const PageNameArr = [
    {"page" : "/" , "PageName" : "Home"},
    {"page" : "about-us" , "PageName" : "aboutUs"},
    {"page" : "process" , "PageName" : "process"},
    {"page" : "knowledge" , "PageName" : "knowledge"},
    {"page" : "special-cut" , "PageName" : "specialCut"},
    {"page" : "faq" , "PageName" : "faq"},
    {"page" : "privacy-policy" , "PageName" : "privacyPolicy"},
    {"page" : "terms-and-conditions" , "PageName" : "termsAndConditions"},
    {"page" : "contact-us" , "PageName" : "contactUs"},
  ];

  const [SeoDetails,setSeoDetails] = useState({
    seo_title : "Ethereal Green Diamond",
    seo_description : "Ethereal Green Diamond",
    seo_keywords : "Ethereal Green Diamond",
  })
  
  useEffect(() => {
    const page = getCurrentPage[0];
    if(page != ""){
      const index = PageNameArr.findIndex(objc => objc.page == page);
      const PageName = PageNameArr[index]?.PageName;
      
      if(PageName){
        const SeoData = Alljson?.data[PageName]
        if(SeoData){
          setSeoDetails({
            seo_title : SeoData?.SeoDetails?.seo_title,
            seo_description : SeoData?.SeoDetails?.seo_description,
            seo_keywords : SeoData?.SeoDetails?.seo_keywords,
          })
        }else{
          setSeoDetails({
            seo_title : "Ethereal Green Diamond",
            seo_description : "Ethereal Green Diamond",
            seo_keywords : "Ethereal Green Diamond",
          })  
        }
      }else{
        setSeoDetails({
          seo_title : "Ethereal Green Diamond",
          seo_description : "Ethereal Green Diamond",
          seo_keywords : "Ethereal Green Diamond",
        })
      }
    }
  }, [getCurrentPage]);

  return (
    <>
      <Helmet>
          <title>{SeoDetails?.seo_title}</title>
          <meta name="description" content={SeoDetails?.seo_description} />
          <meta name="keywords" content={SeoDetails?.seo_keywords} />
          <meta property="og:title" content={SeoDetails?.seo_title} />
          <meta property="og:description" content={SeoDetails?.seo_description} />
      </Helmet>
      <AppContextPageName.Provider value={{ getCurrentPage, dispatchUrlChange }} >
        <Header />
        <Routes />
        <Footer />
      </AppContextPageName.Provider>
    </>
  );
}

export default withRouter(App);
