import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ADMIN_URL } from './APIConfig';
import isEmail from 'validator/lib/isEmail';
import Alljson from '../pages/AllDetails.json';

const SubscribeSection = () => {
    const [GeneralDetails, setGeneralDetails] = useState([]);

    useEffect(() => {
        if(Object.keys(Alljson.data).length > 0){
            if(Alljson.data.SiteDetails){
                setGeneralDetails(Alljson.data.SiteDetails);
            }
        }
    },[])

    function chkBlank(text) {
        if (text != "") {
            return text;
        } else {
            return "";
        }
    }

    /* Subscribe Form */
    const [isSubSubmit,setisSubSubmit] = useState(false);
    const [Email,setEmail] = useState('');
    const [SubMessage, setSubMessage] = useState({ status: "", message: "" });
    const [isSubLoad, setIsSubLoad] = useState(false);
    const handleSubscribeSubmit = (e) => {
        e.preventDefault();
        setisSubSubmit(true);
        if (Email != '' && isEmail(Email)) {
            setIsSubLoad(true);
            const formSubscribe = document.getElementById("formSubscribe");
            if(formSubscribe){
                const formData = new FormData(formSubscribe);
                formData.append('your-email', Email);

                axios.post(`${ADMIN_URL}wp-json/contact-form-7/v1/contact-forms/629/feedback`, formData)
                .then(res => {
                    
                    setisSubSubmit(false);
                    setIsSubLoad(false);
                    if (res.data.status == 'mail_sent') {
                        setSubMessage({status:200,message:res.data.message});
                        setEmail('')
                    } else {
                        setSubMessage({status:412,message:res.data.message});
                    }
                })
            }
        }
    }
    /* Subscribe Form */

    return (
        <div className="section-white newslatter-section">
            <form id='formSubscribe' onSubmit={handleSubscribeSubmit}>
                <div className="container container-big">
                    <div className="bg">
                        <div className="list-main slide-bottom">
                            <div className="list left-tit">{chkBlank(GeneralDetails?.subscribe_section_text)}</div>
                            <div className="list subscribe-form">
                                <div className="form-newslatter">
                                    <input type="text" className="form-control" id="EMAIL" placeholder="your email address" name="EMAIL" onChange={(e) => setEmail(e.target.value)} value={Email} />
                                    {
                                        isSubSubmit && (Email == "" || !isEmail(Email)) ? <div className="error invalid-feedback">{Email == "" ? "Email is required!" : "Email is not valid!"}</div> : ""
                                    }
                                    {
                                        !isSubLoad ? <button className="submit-btn" id='SubscribeBtn' type="submit">Subscribe</button>
                                            : ''
                                    }
                                </div>
                                {
                                    SubMessage.status == 200 ? <div className="alert alert-success">{SubMessage.message}</div> : 
                                    SubMessage.status == 412 ? <div className="alert alert-danger">{SubMessage.message}</div> : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default SubscribeSection