import React, { useContext, useEffect } from 'react'
import AppContext from '../AppContext';
import { NavLink, useHistory } from 'react-router-dom';
import AppendScript from '../components/AppendScript';
import Alljson from './AllDetails.json';
import { scrollBottomToTop } from '../components/Function';
import SubscribeSection from '../components/SubscribeSection';
import useGAEventTracker from '../components/useGAEventTracker';

function FAQ() {
    const history = useHistory();
    const { dispatchUrlChange } = useContext(AppContext);

    const FAQPage = Alljson?.data?.faq;
    const GeneralDetails = Alljson?.data?.SiteDetails;

    useEffect(() => {
        dispatchUrlChange('CHNAGE_URLNAME', { data: ['faq'] });
        document.body.classList.remove('special-cut-page');
        scrollBottomToTop();
    }, [])

    function chkBlank(text) {
        if (text != "") {
            return text;
        } else {
            return "";
        }
    }

    const GAEventsTracker = useGAEventTracker("Links");

    const ContentClickHandler = (e) => {
        const targetLink = e.target.closest('a');
        if (!targetLink) return;
        e.preventDefault();
        if (targetLink.href) {
            const targetBlank = targetLink.target;

            if(targetBlank == "_blank"){
                window.open(targetLink.href, '_blank');
                GAEventsTracker(targetLink.href);
            }else{
                const link = targetLink.href.split("/").pop();
                if (link) {
                    history.push(`/${link}`);
                }
            }
        }
    }

    return (
        <>
            <div className="wrapper">
                {
                    FAQPage?.pageBanner != "" ?
                        <div className="subpage-banner" style={chkBlank(FAQPage?.pageBanner?.background_image) ? { backgroundImage: `url(${FAQPage.pageBanner.background_image})` } : {}}>
                            <div className="container container-big">
                                <ul className="breadcrumb slide-top">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li>Faqs</li>
                                </ul>
                                <h1 className="page-name slide-top">{chkBlank(FAQPage?.pageBanner?.main_title)}</h1>
                            </div>
                        </div>
                        : ''
                }
                {/* <!--/.home-slider--> */}
                {
                    FAQPage?.pageContent?.length > 0 ?
                    <>
                        <div className="clear"></div>
                        <div className="content-main">
                            {
                                FAQPage.pageContent.map(function (ele, i) {
                                    if (ele?.acf_fc_layout == "multiple_accordion_faq") {
                                        return(
                                            <div className="section-white faq-section pdt-0">
                                                <div className="container container-big">
                                                    {
                                                        ele?.accordion?.length > 0 ?
                                                        <>
                                                            <div className="accordion accordion-style2" id="accordionExample">
                                                                {
                                                                    ele.accordion.map(function(acc,a){
                                                                        return(
                                                                            <div className="accordion-item slide-top">
                                                                                <h2 className="accordion-header" id={`heading${a}`}>
                                                                                    <button className={`accordion-button ${a > 0 ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${a}`} aria-expanded={a == 0 ? true : false} aria-controls={`collapse${a}`}>
                                                                                        <div className="accordion-button-inn">
                                                                                            <div className="accordion-name">{chkBlank(acc?.title)}</div>
                                                                                            <div className="accordion-icon"><svg className="minus-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0 0 114.6 0 256s114.6 256 256 256zm-72-280h144c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z" /></svg><svg className="plus-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0 0 114.6 0 256s114.6 256 256 256zm-24-168v-64h-64c-13.3 0-24-10.7-24-24s10.7-24 24-24h64v-64c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24h-64v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" /></svg></div>
                                                                                        </div>
                                                                                    </button>
                                                                                </h2>
                                                                                <div id={`collapse${a}`} className={`accordion-collapse collapse ${a == 0 ? 'show' : ''}`} aria-labelledby={`heading${a}`} data-bs-parent="#accordionExample">
                                                                                    <div className="accordion-body" onClick={ContentClickHandler} dangerouslySetInnerHTML={{__html : chkBlank(acc?.description)}}></div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </>
                                                        : ''
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                            
                            <SubscribeSection />
                            <div id="parallax" className="section-white follow-our-journey-section mgb">
                                <div className="container">
                                    <div className="caption">
                                        <div className="caption-inn slide-top">
                                            <h2 className="title-section text-white">Follow our journey</h2>
                                            {
                                                chkBlank(GeneralDetails?.instagram_link) ?
                                                    <a className="btn btn-white" target="_blank" href={GeneralDetails?.instagram_link} onClick={e => GAEventsTracker("Social Media",GeneralDetails.instagram_link)}>{chkBlank(GeneralDetails.instagram_button_label)}</a>
                                                : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--/.content-main--> */}
                    </>
                    : ''
                }
                <AppendScript />
            </div>
        </>
    )
}

export default FAQ