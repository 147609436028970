import React, { useContext, useEffect, useState } from 'react'
import isEmail from 'validator/lib/isEmail';
import AppContext from '../AppContext';
import { ADMIN_URL } from '../components/APIConfig';
import { NavLink, useHistory } from 'react-router-dom';
import AppendScript from '../components/AppendScript';
import axios from 'axios';
import Alljson from './AllDetails.json';
import { scrollBottomToTop } from '../components/Function';
import SubscribeSection from '../components/SubscribeSection';
import useGAEventTracker from '../components/useGAEventTracker';

function Contact() {
    const history = useHistory();
    const { dispatchUrlChange } = useContext(AppContext);

    const Page = Alljson?.data?.contactUs;
    const GeneralDetails = Alljson?.data?.SiteDetails;

    useEffect(() => {
        dispatchUrlChange('CHNAGE_URLNAME', { data: ['contact-us'] });
        document.body.classList.remove('special-cut-page');
        scrollBottomToTop();
    },[])

    function chkBlank(text) {
        if (text != "") {
            return text;
        } else {
            return "";
        }
    }

    /* Contact Form */
    const [isSubmit, setisSubmit] = useState(false);
    const [SubmitMessage, setSubmitMessage] = useState({ status: "", message: "" });
    const [isSubmitLoad, setIsSubmitLoad] = useState(false);

    const [Data, SetData] = useState({
        homecontactname: "",
        homecontactemail: "",
        homecontactmessage: ""
    });

    const handleForm = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        SetData((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setisSubmit(true);
        if (Data?.homecontactname !== "" && Data?.homecontactemail !== "" && Data?.homecontactmessage !== "" && isEmail(Data?.homecontactemail)) {
            setIsSubmitLoad(true);
            const form = document.getElementById("formId");
            const contactName = Data?.homecontactname;
            const contactEmail = Data?.homecontactemail;
            const contactMessage = Data?.homecontactmessage;
            if (form) {
                const formData = new FormData(form);
                formData.append('your-name', contactName);
                formData.append('your-email', contactEmail);
                formData.append('your-message', contactMessage);
    
                axios.post(`${ADMIN_URL}wp-json/contact-form-7/v1/contact-forms/363/feedback`, formData)
                .then(res => {
                    
                    setIsSubmitLoad(false);
                    setisSubmit(false);
                    if (res.data.status == 'mail_sent') {
                        setSubmitMessage({status:200,message:res.data.message});
                        SetData({
                            homecontactname: "",
                            homecontactemail: "",
                            homecontactmessage: ""
                        })
                    } else {
                        setSubmitMessage({status:412,message:res.data.message});
                    }
                })
            }
        }
    }
    /* Contact Form */

    /* Contact Form 2 */
    const [isSubmit2, setisSubmit2] = useState(false);
    const [SubmitMessage2, setSubmitMessage2] = useState({ status: "", message: "" });
    const [isSubmitLoad2, setIsSubmitLoad2] = useState(false);

    const [Data2, SetData2] = useState({
        homecontactname2: "",
        homecontactemail2: "",
        homecontactmessage2: ""
    });

    const handleForm2 = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        SetData2((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }

    const handleSubmit2 = (e) => {
        e.preventDefault();
        setisSubmit2(true);
        if (Data2?.homecontactname2 !== "" && Data2?.homecontactemail2 !== "" && Data2?.homecontactmessage2 !== "" && isEmail(Data2?.homecontactemail2)) {
            setIsSubmitLoad2(true);
            const form = document.getElementById("formId2");
            const contactName = Data2?.homecontactname2;
            const contactEmail = Data2?.homecontactemail2;
            const contactMessage = Data2?.homecontactmessage2;
            if (form) {
                const formData = new FormData(form);
                formData.append('your-name', contactName);
                formData.append('your-email', contactEmail);
                formData.append('your-message', contactMessage);
    
                axios.post(`${ADMIN_URL}wp-json/contact-form-7/v1/contact-forms/765/feedback`, formData)
                .then(res => {
                    
                    setIsSubmitLoad2(false);
                    setisSubmit2(false);
                    if (res.data.status == 'mail_sent') {
                        setSubmitMessage2({status:200,message:res.data.message});
                        SetData2({
                            homecontactname2: "",
                            homecontactemail2: "",
                            homecontactmessage2: ""
                        })
                    } else {
                        setSubmitMessage2({status:412,message:res.data.message});
                    }
                })
            }
        }
    }
    /* Contact Form 2 */

    const GAEventsTracker = useGAEventTracker("Links");

    const ContentClickHandler = (e) => {
        const targetLink = e.target.closest('a');
        if (!targetLink) return;
        e.preventDefault();
        if (targetLink.href) {
            const targetBlank = targetLink.target;

            if(targetBlank == "_blank"){
                window.open(targetLink.href, '_blank');
                GAEventsTracker(targetLink.href);
            }else{
                const link = targetLink.href.split("/").pop();
                if (link) {
                    history.push(`/${link}`);
                }
            }
        }
    }

    return (
        <>
            <div className="wrapper">
                {
                    Page?.pageBanner != "" ?
                        <div className="subpage-banner" style={chkBlank(Page?.pageBanner?.background_image) ? { backgroundImage: `url(${Page.pageBanner.background_image})` } : {}}>
                            <div className="container container-big">
                                <ul className="breadcrumb slide-top">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li>Contact</li>
                                </ul>
                                <h1 className="page-name slide-top">{Page?.pageBanner?.main_title}</h1>
                            </div>
                        </div>
                    : ''
                }
                {/* <!--/.home-slider--> */}
                {
                    Page?.pageContent?.length > 0 ?
                        <>
                            <div className="clear"></div>
                            <div className="content-main">
                                <div className="section-white overflow-inherit contact-section pdt-0">
                                    <div className="container container-big">
                                        <div className="list-main">
                                            {
                                                Page.pageContent.map(function (ele, i) {
                                                    if (ele?.acf_fc_layout == "contact_page_section") {
                                                        return (
                                                            <>
                                                            <div className="list left-content" key={`sec-${i}`}>
                                                                <div className="bg">
                                                                    {
                                                                        ele?.section_list?.length > 0 ?
                                                                            ele.section_list.map(function(sec,s){
                                                                                return(
                                                                                    <React.Fragment key={`sec-${s}`}>
                                                                                        <h2 className="tit1 slide-top"><span className="icon">
                                                                                            {
                                                                                                chkBlank(sec?.image) ?
                                                                                                    <img className="feather" src={sec.image} alt="" title="" />
                                                                                                : ''
                                                                                            }
                                                                                        {chkBlank(sec?.title)}</span></h2>
                                                                                        <div className="slide-top">
                                                                                            <p className="tit2">{chkBlank(sec?.sub_title)}</p>
                                                                                            {
                                                                                                sec?.country_list == "Yes" ?
                                                                                                    <>
                                                                                                        {
                                                                                                            sec?.description_list?.length > 0 ?
                                                                                                                <>
                                                                                                                    <ul className="con-list">
                                                                                                                        {
                                                                                                                            sec.description_list.map(function(contact,c){
                                                                                                                                return(
                                                                                                                                    <li className="slide-top" key={`cont-${c}`}>
                                                                                                                                        <div className="list-main2">
                                                                                                                                            <div className="list2">{chkBlank(contact?.country_name)}</div>
                                                                                                                                            <div className="list2 phone"><a href={`tel:${chkBlank(contact?.contact_number)}`}>{chkBlank(contact?.contact_number)}</a></div>
                                                                                                                                        </div>
                                                                                                                                    </li>
                                                                                                                                )
                                                                                                                            })
                                                                                                                        }        
                                                                                                                    </ul>
                                                                                                                </>
                                                                                                            : ''
                                                                                                        }
                                                                                                    </>
                                                                                                : <p onClick={ContentClickHandler} dangerouslySetInnerHTML={{__html : chkBlank(sec?.description)}}></p>
                                                                                            }
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )
                                                                            })
                                                                        : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                            </>
                                                        )
                                                    }
                                                })
                                            }
                                            {/* <form method="post" enctype="multipart/form-data"> */}
                                            <div className="list right-content">
                                                <form id='formId' onSubmit={handleSubmit}>
                                                    <h2 className="title-section slide-top">Send us a message</h2>
                                                    <div className="bg">
                                                        <p className="tit3 slide-top">We love hearing from you, Please contact us about any of your diamond or jewellery requirements.</p>
                                                        <div className="form-group slide-top">
                                                            <label className="control-label">Name</label>
                                                            <input type="text" className="form-control border-white2" id="homecontactname" name="homecontactname" onChange={(e) => handleForm(e)} value={Data.homecontactname} />
                                                            {
                                                                isSubmit && Data.homecontactname == ""?<div className="error invalid-feedback">Name is required!</div>:""
                                                            }
                                                        </div>
                                                        <div className="form-group slide-top">
                                                            <label className="control-label">Email</label>
                                                            <input type="email" className="form-control border-white2" id="homecontactemail" name="homecontactemail" onChange={(e) => handleForm(e)} value={Data.homecontactemail} />
                                                            {
                                                                isSubmit && (Data.homecontactemail=="" || !isEmail(Data.homecontactemail))?<div className="error invalid-feedback">{Data.homecontactemail==""?"Email is required!":"Email is not valid!"}</div>:""
                                                            }
                                                        </div>
                                                        <div className="form-group slide-top">
                                                            <label className="control-label">Message</label>
                                                            <textarea className="form-control border-white2" id="homecontactmessage" name="homecontactmessage" onChange={(e) => handleForm(e)} value={Data.homecontactmessage}></textarea>
                                                            {
                                                                isSubmit && Data.homecontactmessage == ""?<div className="error invalid-feedback">Message is required!</div>:""
                                                            }
                                                        </div>
                                                        <div className="form-submit">
                                                            {
                                                                !isSubmitLoad ? <button className="btn" id='sendContactMail' type="submit">Send</button>
                                                                : ''
                                                            }
                                                        </div>
                                                        {
                                                            SubmitMessage.status == 200 ? <div className="alert alert-success">{SubmitMessage.message}</div> : 
                                                            SubmitMessage.status == 412 ? <div className="alert alert-danger">{SubmitMessage.message}</div> : ''
                                                        }
                                                    </div>
                                                </form>
                                                <div className="clear"></div>
                                                <div className="contact-from2">
                                                    <form id='formId2' onSubmit={handleSubmit2}>
                                                        <h2 className="title-section slide-top">Customer Grievance</h2>
                                                        <div className="bg">
                                                            <p className="tit3 slide-top">We are happy to answer your query.</p>
                                                            <div className="form-group slide-top">
                                                                <label className="control-label">Name</label>
                                                                <input type="text" className="form-control border-white2" id="homecontactname2" name="homecontactname2" onChange={(e) => handleForm2(e)} value={Data2.homecontactname2} />
                                                                {
                                                                    isSubmit2 && Data2.homecontactname2 == ""?<div className="error invalid-feedback">Name is required!</div>:""
                                                                }
                                                            </div>
                                                            <div className="form-group slide-top">
                                                                <label className="control-label">Email</label>
                                                                <input type="email" className="form-control border-white2" id="homecontactemail2" name="homecontactemail2" onChange={(e) => handleForm2(e)} value={Data2.homecontactemail2} />
                                                                {
                                                                    isSubmit2 && (Data2.homecontactemail2=="" || !isEmail(Data2.homecontactemail2))?<div className="error invalid-feedback">{Data2.homecontactemail2==""?"Email is required!":"Email is not valid!"}</div>:""
                                                                }
                                                            </div>
                                                            <div className="form-group slide-top">
                                                                <label className="control-label">Message</label>
                                                                <textarea className="form-control border-white2" id="homecontactmessage2" name="homecontactmessage2" onChange={(e) => handleForm2(e)} value={Data2.homecontactmessage2}></textarea>
                                                                {
                                                                    isSubmit2 && Data2.homecontactmessage2 == ""?<div className="error invalid-feedback">Message is required!</div>:""
                                                                }
                                                            </div>
                                                            <div className="form-submit">
                                                                {
                                                                    !isSubmitLoad2 ? <button className="btn" id='sendContactMail2' type="submit">Send</button>
                                                                    : ''
                                                                }
                                                            </div>
                                                            {
                                                                SubmitMessage2.status == 200 ? <div className="alert alert-success">{SubmitMessage2.message}</div> : 
                                                                SubmitMessage2.status == 412 ? <div className="alert alert-danger">{SubmitMessage2.message}</div> : ''
                                                            }
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="list right-content">
                                            </div>
                                            {/* </form> */}
                                        </div>
                                    </div>
                                </div>
                                
                                <SubscribeSection />
                                <div id="parallax" className="section-white follow-our-journey-section mgb">
                                    <div className="container">
                                        <div className="caption">
                                            <div className="caption-inn slide-top">
                                                <h2 className="title-section text-white">Follow our journey</h2>
                                                {
                                                    chkBlank(GeneralDetails?.instagram_link) ?
                                                        <a className="btn btn-white" target="_blank" href={GeneralDetails?.instagram_link} onClick={e => GAEventsTracker("Social Media",GeneralDetails.instagram_link)}>{chkBlank(GeneralDetails.instagram_button_label)}</a>
                                                        : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--/.content-main--> */}
                        </>
                    : ''
                }
                <AppendScript />
            </div>
        </>
    )
}

export default Contact