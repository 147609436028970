import React, { useContext, useEffect, useState } from 'react'
import { ADMIN_URL } from './APIConfig';
import AppContext from '../AppContext';
import { NavLink, useHistory } from 'react-router-dom';
import Alljson from '../pages/AllDetails.json';
import axios from 'axios';
import isEmail from 'validator/lib/isEmail';
import { scrollBottomToTop } from './Function';
import useGAEventTracker from './useGAEventTracker';

function Footer() {
    const history = useHistory();
    const { getCurrentPage, dispatchUrlChange } = useContext(AppContext);
    const slugName = (getCurrentPage) ? getCurrentPage[0] : '';
    const GeneralDetails = Alljson?.data?.SiteDetails;

    function chkBlank(text){
        if(text != ""){
            return text;
        }else{
            return "";
        }
    }

    /* Contact Form */
    const [Data, SetData] = useState({
        homecontactname: "",
        homecontactemail: "",
        homecontactmessage: ""
    });

    const [isSubmit, setisSubmit] = useState(false);
    const [SubmitMessage, setSubmitMessage] = useState({ status: "", message: "" });
    const [isSubmitLoad, setIsSubmitLoad] = useState(false);

    const handleForm = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        SetData((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setisSubmit(true);
        if (Data?.homecontactname !== "" && Data?.homecontactemail !== "" && Data?.homecontactmessage !== "" && isEmail(Data?.homecontactemail)) {
            setIsSubmitLoad(true);
            const form = document.getElementById("formFooterId");
            const contactName = Data?.homecontactname;
            const contactEmail = Data?.homecontactemail;
            const contactMessage = Data?.homecontactmessage;
            if (form) {
                const formData = new FormData(form);
                formData.append('your-name', contactName);
                formData.append('your-email', contactEmail);
                formData.append('your-message', contactMessage);

                axios.post(`${ADMIN_URL}wp-json/contact-form-7/v1/contact-forms/363/feedback`, formData)
                .then(res => {
                    
                    setIsSubmitLoad(false);
                    setisSubmit(false);
                    if (res.data.status == 'mail_sent') {
                        setSubmitMessage({status:200,message:res.data.message});
                        SetData({
                            homecontactname: "",
                            homecontactemail: "",
                            homecontactmessage: ""
                        })
                    } else {
                        setSubmitMessage({status:412,message:res.data.message});
                    }
                })
            }
        }
    }
    /* Contact Form */

    const handleClick = () => {
        SetData({
            homecontactname: "",
            homecontactemail: "",
            homecontactmessage: ""
        })
        setIsSubmitLoad(false);
        setisSubmit(false);
    };

    window.addEventListener('click',(event) => {
        const scrollToTop = event.target.classList.contains('scrollToTop');
        if(scrollToTop){
            scrollBottomToTop();
        }
    })

    useEffect(() => {
        const unlisten = history.listen(() => {
          handleClick();
        });
    
        return () => {
          unlisten();
        };
    }, [history]);

    const d = new Date();
    let Year = d.getFullYear();

    const GAEventsTracker = useGAEventTracker("Links");

    return (
        <>
            <div className="footer-main">
                
                <a id="scrollTopBtn" onClick={handleClick} className="scroll-btn hide scrollToTop"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-up scrollToTop"><polyline points="18 15 12 9 6 15"></polyline></svg></a>
                <div className="footer-top">
                    <div className="container">
                        <form id='formFooterId' onSubmit={handleSubmit}>
                            <div className="list-main">
                                <div className="list f-logo-main">
                                    <NavLink className="f-logo" to="/">
                                        {
                                            chkBlank(GeneralDetails?.footer_logo) ?
                                                <img src={GeneralDetails.footer_logo} title="" alt="" />
                                            : ''
                                        }
                                    </NavLink>
                                </div>
                                <div className="list f-address">
                                    <h4 className="tit">{chkBlank(GeneralDetails?.website_name)}</h4>
                                    <ul className="f-address-main">
                                        <li className="address" dangerouslySetInnerHTML={{__html : chkBlank(GeneralDetails?.website_address)}}></li>
                                        {
                                            chkBlank(GeneralDetails.website_contact_phone) ? 
                                                <li className="icon"><span><img className="icon-img" src="./images/icon/mobile-icon.png" alt="" /><a href={`tel:${chkBlank(GeneralDetails.website_contact_phone)?.replace(/\s+/g, '-')}`}>{chkBlank(GeneralDetails.website_contact_phone)}</a></span></li>
                                            : ''
                                        }
                                        <li className="icon"><span><img className="icon-img" src="./images/icon/phone-icon.png" alt="" /><a href={`tel:${chkBlank(GeneralDetails.website_landline_number)?.replace(/\s+/g, '-')}`}>{chkBlank(GeneralDetails.website_landline_number)}</a></span></li>
                                        <li className="icon"><span><img className="icon-img" src="./images/icon/email-icon.png" alt="" /><a href="mailto:sales@etherealdiamond.com">{chkBlank(GeneralDetails.website_email)}</a></span></li>
                                    </ul>
                                    {
                                        GeneralDetails?.follows_us_links?.length > 0 ?
                                            <>
                                                <ul className="f-social" key={`ul-44`}>
                                                    <li key={`link-77`}>Follows us on</li>
                                                    {
                                                        GeneralDetails.follows_us_links.map(function(links,l){
                                                            return(
                                                                <>
                                                                    {
                                                                        chkBlank(links?.link) ?
                                                                            <li className={links?.class} key={`link-${l}`}>
                                                                                <a target="_blank" href={links.link} rel="noopener" onClick={e => GAEventsTracker("Social Media",links.link)}>{links?.class}</a>
                                                                            </li>
                                                                        : ''
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </>
                                        : ''
                                    }
                                    <ul className="app-list">
                                        <li className="name">Download the App</li>
                                        {
                                            chkBlank(GeneralDetails?.android_app_link) != "" ? 
                                                <li>
                                                    <a target="_blank" href={GeneralDetails?.android_app_link} rel="noopener" onClick={e => GAEventsTracker("Mobile Application",GeneralDetails.android_app_link)}><img className="app-icon" src="./images/google-play-logo.svg" title="" alt="" /></a>
                                                </li>
                                            : ''
                                        }
                                        {
                                            chkBlank(GeneralDetails?.apple_app_link) != "" ? 
                                                <li>
                                                    <a target="_blank" href={GeneralDetails?.apple_app_link} rel="noopener" onClick={e => GAEventsTracker("Mobile Application",GeneralDetails.apple_app_link)}><img className="app-icon" src="./images/app-store-logo.svg" title="" alt="" /></a>
                                                </li>
                                            : ''
                                        }
                                    </ul>
                                </div>
                                <div className="list f-menu-main">
                                    <h4 className="tit">Quick Links</h4>
                                    <ul className="f-menu">
                                        <li {...((slugName == "" || slugName == "/") && { className: 'active' })}><NavLink to="/"><span className='scrollToTop'>Home</span></NavLink></li>
                                        <li {...(slugName == "about-us" && { className: 'active' })}><NavLink to="/about-us"><span className='scrollToTop'>About Us</span></NavLink></li>
                                        <li className={`${(slugName == "inventory") ? 'active' : ''}`}><a href={chkBlank(GeneralDetails?.login_url) ? GeneralDetails.login_url : "/"} target='_blank' ><span className=''>Inventory</span></a></li>
                                        <li className={`single-sub-menu ${(slugName == "process") ? 'active' : ''}`}><NavLink to="/process"><span className='scrollToTop'>Process</span></NavLink></li>
                                        <li {...(slugName == "knowledge" && { className: 'active' })}><NavLink to="/knowledge"><span className='scrollToTop'>Knowledge</span></NavLink></li>
                                        <li {...(slugName == "contact-us" && { className: 'active' })}><NavLink to="/contact-us"><span className='scrollToTop'>Contact Us</span></NavLink></li>
                                        <li {...(slugName == "faq" && { className: 'active' })}><NavLink to="/faq"><span className='scrollToTop'>Faqs</span></NavLink></li>
                                        <li {...(slugName == "special-cut" && { className: 'active' })}><NavLink to="/special-cut"><span className='scrollToTop'>Special Cut</span></NavLink></li>
                                        {/* <li><a href="javascript:;">Log In</a></li> */}
                                    </ul>
                                    <div className="f-certified-list">
                                        <div className="list2">
                                            {
                                                chkBlank(GeneralDetails?.igi_certificate_image) ?
                                                    <img src={GeneralDetails.igi_certificate_image} alt="" title="" />
                                                : ''
                                            }
                                        </div>
                                        <div className="list2">
                                            {
                                                chkBlank(GeneralDetails?.gia_certificate_image) ?
                                                    <img src={GeneralDetails.gia_certificate_image} alt="" title="" />
                                                : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="list f-get-in-touch">
                                    <h4 className="tit">Get in Touch</h4>
                                    <div className="form-group">
                                        <label className="control-label">Name</label>
                                        <input type="text" className="form-control" id="homecontactname" name="homecontactname" onChange={(e) => handleForm(e)} value={Data.homecontactname} />
                                        {
                                            isSubmit && Data.homecontactname == ""?<div className="error invalid-feedback">Name is required!</div>:""
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Email</label>
                                        <input type="text" className="form-control" id="homecontactemail" name="homecontactemail" onChange={(e) => handleForm(e)} value={Data.homecontactemail} />
                                        {
                                            isSubmit && (Data.email=="" || !isEmail(Data.homecontactemail))?<div className="error invalid-feedback">{Data.homecontactemail==""?"Email is required!":"Email is not valid!"}</div>:""
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Message</label>
                                        <textarea className="form-control" id="homecontactmessage" name="homecontactmessage" onChange={(e) => handleForm(e)} value={Data.homecontactmessage}></textarea>
                                        {
                                            isSubmit && Data.homecontactmessage == ""?<div className="error invalid-feedback">Message is required!</div>:""
                                        }
                                    </div>
                                    <div className="form-submit">
                                        {
                                            !isSubmitLoad ? <button className="btn" id='saveFooterForm' type="submit">Send</button>
                                            : ''
                                        }
                                    </div>
                                    {
                                        SubmitMessage.status == 200 ? <div className="alert alert-success">{SubmitMessage.message}</div> : 
                                        SubmitMessage.status == 412 ? <div className="alert alert-danger">{SubmitMessage.message}</div> : ''
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="bottom-inn">
                            <ul className="bottom-left">
                                <li className="copy"><script>var dteNow = new Date(); var intYear = dteNow.getFullYear(); document.write(intYear);</script>
                                © {Year}, Ethereal Green Diamond. {chkBlank(GeneralDetails.copy_right_text)}</li>
                            </ul>
                            <ul className="bottom-left f-menu">
                                <li {...(slugName == "privacy-policy" && { className: 'active' })}><NavLink to="/privacy-policy"><span className='scrollToTop'>Privacy Policy</span></NavLink></li>
                                <li {...(slugName == "terms-and-conditions" && { className: 'active' })}><NavLink to="/terms-and-conditions"><span className='scrollToTop'>Terms And Conditions</span></NavLink></li>
                                <li className="design-by">
                                    {/* <!-- <span>powered by</span>  --> */}
                                    <a title="Enliven Digital Content" target="_blank" href="https://enlivendc.com/" rel="noopener">enliven</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer