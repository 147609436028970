import { Route, Switch } from "react-router-dom";
import Contact from "../pages/Contact";
import HomePage from "../pages/HomePage";
import AboutUs from "../pages/AboutUs";
import Process from "../pages/Process";
import Knowledge from "../pages/Knowledge";
import SpecialCut from "../pages/SpecialCut";
import FAQ from "../pages/FAQ";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermConditions from "../pages/TermConditions";
import Inventory from "../pages/Inventory";

function Routes() {
    return (
        <Switch>
            <Route exact path="/" component={HomePage}/>
            <Route path="/home" component={HomePage}/>
            <Route path="/about-us" component={AboutUs} />
            <Route path="/process" component={Process} />
            <Route path="/knowledge" component={Knowledge} />
            <Route path="/special-Cut" component={SpecialCut} />
            <Route path="/faq" component={FAQ}/>
            <Route path="/privacy-policy" component={PrivacyPolicy}/>
            <Route path="/terms-and-conditions" component={TermConditions}/>
            <Route path="/inventory" component={Inventory}/>
            <Route path="/contact-us" component={Contact}/>
        </Switch>
    )
}

export default Routes