import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import AppContext from '../AppContext';
import Alljson from '../pages/AllDetails.json';

function Header() {
    const { getCurrentPage, dispatchUrlChange } = useContext(AppContext);
    const slugName = (getCurrentPage) ? getCurrentPage[0] : '';
    
    if(slugName == "special-cut"){
        document.body.classList.add('special-cut-page');
    }else{
        document.body.classList.remove('special-cut-page');
    }

    const GeneralDetails = Alljson?.data?.SiteDetails;

    useEffect(() => {
		document.body.classList.add('special-cut-page');
        toggleMenu();

        window.addEventListener('resize',function(){
            if (window.innerWidth < 1000) {
                setShowNavbar(false);
            }
        });
	}, [])

    function chkBlank(text) {
		if (text != "") {
			return text;
		} else {
			return "";
		}
	}

    const [showNavbar, setShowNavbar] = useState(true);

    const toggleMenu = () => {
        if (window.innerWidth < 1000) {
            setShowNavbar(!showNavbar);
        }else{
            setShowNavbar(true);
        }
    }

    window.addEventListener('resize',function(){
        if (window.innerWidth < 1000) {
            setShowNavbar(false);
        }
    });

    ['load', 'click'].forEach(function(e) {
        window.addEventListener(e,function(){
            const menuBtn = document.getElementById('menu-main');
            if(menuBtn){
                toggleMenu();
            }
        })
    })

    return (
        <>
            <div className="header-main nav-up" id='header-main'>
                <div className="container container-big">
                    <div className="logo-part">
                        <div className="logo">
                            <NavLink className="logo-img" to="/">
                                {
                                    chkBlank(GeneralDetails?.logo_image) ?
                                        <img className="logo-blue" src={GeneralDetails.logo_image} alt="" />
                                    : ''
                                }
                                {
                                    chkBlank(GeneralDetails?.logo_image_white) ?
                                        <img className="logo-white" src={GeneralDetails.logo_image_white} alt="" />
                                    : ''
                                }
                            </NavLink>
                        </div>
                        <div className="menu-main">
                            {/* <!-- Menu Toggle btn--> */}
                            <div className="menu-toggle" onClick={toggleMenu}>
                                <h3 className="menu-tit">Menu</h3>
                                <button className="ToggleMenu MenuButton menu-button toggle" type="button" id="menu-btn2" onClick={toggleMenu}><div className="inn-btn"><span className="fa-line"></span><span className="fa-line2"></span><span className="fa-line3"></span></div></button>
                            </div>
                            {/* <!-- Responsive Menu Structure--> */}
                            <ul className={`ace-responsive-menu ${showNavbar ? "show-menu" : "hide-menu"}`}>
                                <div className="header-responsive" onClick={toggleMenu}>
                                    <div className="logowrap-m">
                                        <NavLink className="logo-img" to="/">
                                            {
                                                chkBlank(GeneralDetails?.logo_image) ?
                                                    <img className="logo-blue" src={GeneralDetails.logo_image} alt="" />
                                                : ''
                                            }
                                        </NavLink>
                                    </div>
                                    <button type="button" className="MenuButton menu-button menu-button-m" id="menu-btn" onClick={toggleMenu}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button>
                                </div>
                                <div className="responsive-menu-inn">
                                    <li className={(slugName == "" || slugName == "/") ? 'active' : ''}><NavLink to="/"><span className='scrollToTop' onClick={toggleMenu}>HOME</span></NavLink></li>
                                    <li className={(slugName == "about-us") ? 'active' : ''}><NavLink to="/about-us"><span className='scrollToTop' onClick={toggleMenu}>ABOUT</span></NavLink></li>
                                    <li className={`single-sub-menu ${(slugName == "process") ? 'active' : ''}`}><NavLink to="/process"><span className='scrollToTop' onClick={toggleMenu}>PROCESS</span></NavLink></li>
                                    <li className={(slugName == "knowledge") ? 'active' : ''}><NavLink to="/knowledge"><span className='scrollToTop' onClick={toggleMenu}>KNOWLEDGE</span></NavLink></li>
                                    <li className={(slugName == "special-cut") ? 'active' : ''}><NavLink to="/special-cut"><span className='scrollToTop' onClick={toggleMenu}>SPECIAL CUT</span></NavLink></li>
                                    <li className={(slugName == "contact-us") ? 'active' : ''}><NavLink to="/contact-us"><span className='scrollToTop' onClick={toggleMenu}>CONTACT</span></NavLink></li>
                                    <li className="login-menu">
                                        <a className="login-btn hvr-sweep-to_right" href={chkBlank(GeneralDetails?.login_url) ? GeneralDetails.login_url : "/"} target='_blank'><span className='scrollToTop' onClick={toggleMenu}>LOG IN</span></a>
                                        <a className="register-btn login-btn hvr-sweep-to_right" href={chkBlank(GeneralDetails?.register_url) ? GeneralDetails.register_url : "/"} target='_blank'><span className='scrollToTop' onClick={toggleMenu}>REGISTER</span></a>
                                    </li>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header