import React, { useContext, useEffect } from 'react'
import AppContext from '../AppContext';
import { NavLink, useHistory } from 'react-router-dom';
import AppendScript from '../components/AppendScript';
import Alljson from './AllDetails.json';
import { scrollBottomToTop } from '../components/Function';
import SubscribeSection from '../components/SubscribeSection';
import useGAEventTracker from '../components/useGAEventTracker';

function PrivacyPolicy() {
    const history = useHistory();
    const { dispatchUrlChange } = useContext(AppContext);

    const Page = Alljson?.data?.privacyPolicy;
    const GeneralDetails = Alljson?.data?.SiteDetails;

    useEffect(() => {
        dispatchUrlChange('CHNAGE_URLNAME', { data: ['privacy-policy'] });
        document.body.classList.remove('special-cut-page');
        scrollBottomToTop();
    }, [])

    function chkBlank(text) {
        if (text != "") {
            return text;
        } else {
            return "";
        }
    }

    const GAEventsTracker = useGAEventTracker("Links");

    const ContentClickHandler = (e) => {
        const targetLink = e.target.closest('a');
        if (!targetLink) return;
        e.preventDefault();
        if (targetLink.href) {
            const targetBlank = targetLink.target;

            if(targetBlank == "_blank"){
                window.open(targetLink.href, '_blank');
                GAEventsTracker(targetLink.href);
            }else{
                const link = targetLink.href.split("/").pop();
                if (link) {
                    history.push(`/${link}`);
                }
            }
        }
    }

    return (
        <>
            <div className="wrapper">
                {
                    Page?.pageBanner != "" ?
                        <div className="subpage-banner" style={chkBlank(Page?.pageBanner?.background_image) ? { backgroundImage: `url(${Page.pageBanner.background_image})` } : {}}>
                            <div className="container container-big">
                                <ul className="breadcrumb slide-top">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li>Privacy Policy</li>
                                </ul>
                                <h1 className="page-name slide-top">{Page?.pageBanner?.main_title}</h1>
                            </div>
                        </div>
                        : ''
                }
                {/* <!--/.home-slider--> */}
                {
                    Page?.pageContent?.length > 0 ?
                        <>
                            <div className="clear"></div>
                            <div className="content-main">
                                {
                                    Page.pageContent.map(function (ele, i) {
                                        if (ele?.acf_fc_layout == "full_section") {
                                            return (
                                                <div className="section-white privacy-policy-section pdt-0">
                                                    <div className="container container-big" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(ele?.content) }}></div>
                                                </div>
                                            )
                                        }
                                    })
                                }

                                <SubscribeSection />
                                <div id="parallax" className="section-white follow-our-journey-section mgb">
                                    <div className="container">
                                        <div className="caption">
                                            <div className="caption-inn slide-top">
                                                <h2 className="title-section text-white">Follow our journey</h2>
                                                {
                                                    chkBlank(GeneralDetails?.instagram_link) ?
                                                        <a className="btn btn-white" target="_blank" href={GeneralDetails?.instagram_link} onClick={e => GAEventsTracker("Social Media",GeneralDetails.instagram_link)}>{chkBlank(GeneralDetails.instagram_button_label)}</a>
                                                        : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--/.content-main--> */}
                        </>
                        : ''
                }
                <AppendScript />
            </div>
        </>
    )
}

export default PrivacyPolicy