import React, { useContext, useEffect } from 'react'
import AppContext from '../AppContext';
import { NavLink, useHistory } from 'react-router-dom';
import AppendScript from '../components/AppendScript';
import Alljson from './AllDetails.json';
import Slider from 'react-slick';
import { scrollBottomToTop } from '../components/Function';
import SubscribeSection from '../components/SubscribeSection';
import useGAEventTracker from '../components/useGAEventTracker';

function HomePage() {
    const history = useHistory();
    const { dispatchUrlChange } = useContext(AppContext);
    
    const HomePage = Alljson?.data?.Home;
    const GeneralDetails = Alljson?.data?.SiteDetails;
    useEffect(() => {
        dispatchUrlChange('CHNAGE_URLNAME', { data: ['/'] });
        document.body.classList.remove('special-cut-page');
        scrollBottomToTop();
    }, [])

    function chkBlank(text) {
        if (text != "") {
            return text;
        } else {
            return "";
        }
    }

    const GAEventsTracker = useGAEventTracker("Links");

    const ContentClickHandler = (e) => {
        const targetLink = e.target.closest('a');
        if (!targetLink) return;
        e.preventDefault();
        if (targetLink.href) {
            const targetBlank = targetLink.target;

            if(targetBlank == "_blank"){
                window.open(targetLink.href, '_blank');
                GAEventsTracker(targetLink.href);
            }else{
                const link = targetLink.href.split("/").pop();
                if (link) {
                    history.push(`/${link}`);
                }
            }
        }
    }

    const homeSlider = {
        lazyLoad: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite: true,
        swipeToSlide: true,
        dots: true,
        fade: true,
        cssEase: "linear",
        pauseOnHover: false,
        autoplay: false,
        autoplaySpeed: 5000
    };
    
    const productCutSlider = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false
    };

    return (
        <>
            <div className="wrapper">
                <div className="home-slider">
                    <Slider {...homeSlider}>
                    {
                            HomePage?.pageSlider?.length > 0 ?
                                HomePage.pageSlider.map((ele,i) => (
                                    <div className={`hero-slide slide${i}`} key={i}>
                                        <div className="center">
                                            <div className="slider-cap">
                                                <div className="slider-text" data-animation="fadeInLeft" data-delay="0.5s" dangerouslySetInnerHTML={{ __html: chkBlank(ele?.slider_content) }} onClick={ContentClickHandler}>
                                                </div>
                                                <div className="slider-image" data-animation="fadeInRight" data-delay="0.5s">
                                                    {
                                                        chkBlank(ele?.slide_image) != "" ?
                                                            <img className="desktop-img" src={ele.slide_image} title="" alt="" />
                                                            : ''
                                                    }
                                                    {
                                                        chkBlank(ele?.slide_mobile_image) != "" ?
                                                            <img className="mobile-img" src={ele.slide_mobile_image} title="" alt="" />
                                                            : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            : ''
                        }
                    </Slider>
                </div>
                
                {/* <!--/.home-slider--> */}
                {
                    HomePage?.pageContent?.length > 0 ?
                        <>
                            <div className="clear"></div>
                            <div className="content-main">
                                {
                                    HomePage.pageContent.map(function (ele, i) {
                                        // console.log(ele,i)
                                        if (ele.acf_fc_layout == "our_features_section") {
                                            return (
                                                <div className="section-white home-lab-grown-diamonds pdt-0" key={`sec-${i}`}>
                                                    <div className="container">
                                                        {
                                                            ele?.features?.length > 0 ?
                                                                <>
                                                                    <div className="tabs-style1">
                                                                        <ul className="nav nav-tabs slide-left MenuTabHoverOpen" id="myTab" role="tablist">
                                                                            {
                                                                                ele.features.map(function (fea, f) {
                                                                                    return (
                                                                                        <li className="nav-item" role="presentation" key={`lif-${f}`}>
                                                                                            <button className={`nav-link tab-button ${f == 0 ? 'active' : ''}`} id={`${f}-tab`} data-bs-toggle="tab" data-bs-target={`tab${f}`} type="button" role="tab" aria-controls="carats" aria-selected="true" data-hover="tab">
                                                                                                <div className="list-main">
                                                                                                    <div className="list icon">
                                                                                                        {
                                                                                                            chkBlank(fea.icon) ?
                                                                                                                <img src={fea.icon} title="" alt="" />
                                                                                                                : ''
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="list name">{chkBlank(fea.title)}</div>
                                                                                                    <div className="list arrow">
                                                                                                        <img src="images/icon/arrow-right-white.svg" title="" alt="" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </button>
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                        <div className="tab-content slide-right" id="myTabContent">
                                                                            {
                                                                                ele.features.map(function (fea, f) {
                                                                                    return (
                                                                                        <div className={`tab-pane fade ${f == 0 ? 'active show' : ''}`} id={`tab${f}`} role="tabpanel" aria-labelledby={`${f}-tab`} key={`liCon-${f}`}>
                                                                                            {
                                                                                                fea?.is_image_in_description == "Yes" ?
                                                                                                    <div className="tit">
                                                                                                        {
                                                                                                            fea?.image_description?.length > 0 ?
                                                                                                                <>
                                                                                                                    <div className="certified-list">
                                                                                                                        {
                                                                                                                            fea.image_description.map(function (image, ima) {
                                                                                                                                if (chkBlank(image?.image)) {
                                                                                                                                    return (
                                                                                                                                        <div className="list2" key={`image-${ima}`}><img src={image.image} alt="" title="" /></div>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </>
                                                                                                                : ''
                                                                                                        }
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="tit" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(fea.desctiption) }}>
                                                                                                    </div>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div className="accordion accordion-style1" id="accordionExample">
                                                                        {
                                                                            ele.features.map(function (fea, f) {
                                                                                return (
                                                                                    <div className="accordion-item slide-bottom" key={`acco-${f}`}>
                                                                                        <h2 className="accordion-header" id={`heading${f}`}>
                                                                                            <button className={`accordion-button ${f > 0 ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${f}`} aria-expanded={f == 0 ? 'true' : 'false'} aria-controls={`#collapse${f}`}>
                                                                                                <div className="list-main">
                                                                                                    <div className="list icon">
                                                                                                        {
                                                                                                            chkBlank(fea.icon) ?
                                                                                                                <img src={fea.icon} title="" alt="" />
                                                                                                                : ''
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="list name">{chkBlank(fea.title)}</div>
                                                                                                    <div className="list arrow"><img src="images/icon/arrow-right-white.svg" title="" alt="" /></div>
                                                                                                </div>
                                                                                            </button>
                                                                                        </h2>
                                                                                        <div id={`collapse${f}`} className={`accordion-collapse collapse ${f == 0 ? 'show' : ''}`} aria-labelledby={`heading${f}`} data-bs-parent="#accordionExample">
                                                                                            <div className="accordion-body">
                                                                                                {
                                                                                                    fea?.is_image_in_description == "Yes" ?
                                                                                                    <div className="tit">
                                                                                                        {
                                                                                                            fea?.image_description?.length > 0 ?
                                                                                                                <>
                                                                                                                    <div className="certified-list">
                                                                                                                        {
                                                                                                                            fea.image_description.map(function (image, ima) {
                                                                                                                                if (chkBlank(image?.image)) {
                                                                                                                                    return (
                                                                                                                                        <div className="list2" key={`image-${ima}`}><img src={image.image} alt="" title="" /></div>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </>
                                                                                                                : ''
                                                                                                        }
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="tit" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(fea.desctiption) }}>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </>
                                                                : ''
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        } else if (ele.acf_fc_layout == "two_section_content") {
                                            return (
                                                <div className="section-white bg1 home-about" key={`sec-${i}`}>
                                                    <div className="container">
                                                        <div className="list-mian">
                                                            <div className="list left-content">
                                                                <div className="slide-bottom ethereal-logo-main">
                                                                    {
                                                                        chkBlank(ele?.left_section_title_normal_images) ?
                                                                            <img className="ethereal-logo normal" src={ele.left_section_title_normal_images} title="" alt="" />
                                                                            : ""
                                                                    }
                                                                    {
                                                                        chkBlank(ele?.left_section_title_hover_images) ?
                                                                            <img className="ethereal-logo hover" src={ele.left_section_title_hover_images} title="" alt="" />
                                                                            : ''
                                                                    }
                                                                </div>
                                                                <div className="slide-bottom" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(ele.left_section) }} >

                                                                </div>
                                                            </div>
                                                            <div className="list right-content">
                                                                <div className="tit slide-bottom" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(ele.right_section) }}></div>
                                                            </div>
                                                        </div>
                                                        {
                                                            GeneralDetails?.follows_us_links?.length > 0 ?
                                                                <>
                                                                    <ul className="bottom-social slide-bottom">
                                                                        <li>Follows us on</li>
                                                                        {
                                                                            GeneralDetails.follows_us_links.map(function(links,l){
                                                                                return(
                                                                                    <>
                                                                                        {
                                                                                            chkBlank(links?.link) ?
                                                                                                <li className={links?.class} key={`link-${l}`}>
                                                                                                    <a target="_blank" href={links.link} onClick={e => GAEventsTracker("Social Media",links.link)}>{links?.class}</a>
                                                                                                </li>
                                                                                            : ''
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ''
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        } else if (ele.acf_fc_layout == "cuts_section") {
                                            return (
                                                <>
                                                    <div className="section-white home-product-cuts" key={`sec2-${i}`}>
                                                        <div className="container">
                                                            <div className="list-mian">
                                                                <div className="list left-content slide-bottom">
                                                                    <div className="section-title">
                                                                        <h2 className="title">{chkBlank(ele.section_title)}</h2>
                                                                        <div className="description text-white" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(ele.section_title_description) }}></div>
                                                                    </div>
                                                                    <a className="btn btn-white" href={chkBlank(GeneralDetails?.login_url) ? GeneralDetails.login_url : "/"} target='_blank' onClick={e => GAEventsTracker("Login",GeneralDetails.login_url)}>Shop Inventory</a>
                                                                </div>
                                                                <div className="list right-content slide-bottom">
                                                                    <Slider {...productCutSlider}>
                                                                        {
                                                                            ele?.cuts_type?.length > 0 ?
                                                                                ele.cuts_type.map((cuts,c) => (
                                                                                    <div className="slide-item" key={`cut-${c}`}>
                                                                                        <div className="slide-list-main">
                                                                                            <div className="slide-list left">
                                                                                                {
                                                                                                    chkBlank(cuts.image) != "" ?
                                                                                                    <img className="cut-img" src={cuts.image} title="Round" alt="Round" />
                                                                                                    : ''
                                                                                                }
                                                                                                <h3 className="title-section pdb-0 text-white">{chkBlank(cuts.title)}</h3>
                                                                                            </div>
                                                                                            <div className="slide-list right">
                                                                                                <div className="inn-content" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(cuts.description) }}></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            : ''
                                                                        }
                                                                    </Slider>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        } else if (ele.acf_fc_layout == "process_section") {
                                            return (
                                                <div className="section-white home-process pdb-0 overflow-inherit" key={`sec-${i}`}>
                                                    <div className="container">
                                                        <div className="list-mian">
                                                            <div className="list left-content">
                                                                <div className="process">
                                                                    <div className="tit-section slide-bottom2">
                                                                        <h3 className="tit">{chkBlank(ele?.section_title)}</h3>
                                                                        <div className="desc" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(ele?.section_title_description) }}></div>
                                                                        <NavLink className="btn btn-white" to="/process">Read More</NavLink>
                                                                    </div>
                                                                    {
                                                                        ele?.images.length > 0 ?
                                                                            ele.images.map(function (img, im) {
                                                                                return (
                                                                                    <div className={`process-img${Number(im) + 1} ${im == 1 ? 'slide-top2' : 'slide-bottom2'}`} key={`pro-${im}`}><img className="process-img" src={img} title="Process" alt="Process" /></div>
                                                                                )
                                                                            })
                                                                            : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                            {
                                                                ele?.process_list?.length > 0 ?
                                                                    <div className="list right-content">
                                                                        <ul className="process-list-main slide-bottom">
                                                                            {
                                                                                ele?.process_list.map(function (list, l) {
                                                                                    return (
                                                                                        <li key={`li-${l}`}>
                                                                                            <div className="process-list">
                                                                                                <div className="list2 left-con">
                                                                                                    <div className="num">{chkBlank(list.title)}</div>
                                                                                                    <div className="desc2" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(list.description) }}></div>
                                                                                                </div>
                                                                                                <div className="list2 right-img">
                                                                                                    {
                                                                                                        chkBlank(list.image) ?
                                                                                                            <img className="process2-img" src={list.image} title="" alt="" />
                                                                                                            : ''
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                    : ''
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else if (ele.acf_fc_layout == "image_section_content") {
                                            return (
                                                <div className="section-white home-special-cuts" key={`sec-${i}`}>
                                                    <div className="container container-big">
                                                        <div className="bg">
                                                            <h3 className="title-section text-center slide-bottom">{chkBlank(ele?.section_title)}</h3>
                                                            <div className="special-cuts">
                                                                <div className="slide-bottom">
                                                                    {
                                                                        chkBlank(ele?.section_image) != "" ?
                                                                            <img className="special-cuts-img" src={ele?.section_image} title="The OPTICA Series" alt="The OPTICA Series" />
                                                                            : ''
                                                                    }
                                                                </div>
                                                                <div className="caption slide-bottom" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(ele?.section_description) }}>

                                                                </div>
                                                            </div>
                                                            <div className="text-center slide-bottom"><NavLink className="btn" to="/special-cut">Explore Cuts</NavLink></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else if (ele.acf_fc_layout == "application_download_section") {
                                            return (
                                                <div className="section-white home-app pdt-0" key={`sec-${i}`}>
                                                    <div className="container">
                                                        <div className="bg">
                                                            <div className="list left-content">
                                                                <div className="tit slide-bottom" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(ele?.section_title) }}></div>
                                                                {
                                                                    GeneralDetails?.application?.length > 0 ?
                                                                        <>
                                                                            <ul className="app-list">
                                                                                {
                                                                                    GeneralDetails?.application.map(function (app, a) {
                                                                                        return (
                                                                                            <li key={`ali-${a}`}>
                                                                                                <a target="_blank" href={chkBlank(app?.url)} onClick={e => GAEventsTracker("Mobile Application",app.url)}>
                                                                                                    {
                                                                                                        chkBlank(app?.normal_image) ?
                                                                                                            <img className="app-icon normal" src={app?.normal_image} title="" alt="" />
                                                                                                            : ''
                                                                                                    }
                                                                                                    {
                                                                                                        chkBlank(app?.hover_image) ?
                                                                                                            <img className="app-icon hover" src={app?.hover_image} title="" alt="" />
                                                                                                            : ''
                                                                                                    }
                                                                                                </a>
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                        </>
                                                                        : ''
                                                                }
                                                            </div>
                                                            <div className="list right-image slide-bottom">
                                                                {
                                                                    chkBlank(ele?.image) ?
                                                                        <img className="app-img" src={ele?.image} title="" alt="" />
                                                                        : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }

                                <SubscribeSection />
                            </div>
                            {/* <!--/.content-main--> */}
                        </>
                        : ''
                }
                <AppendScript />
            </div>
            {/* <!--/.wrapper--> */}
        </>
    )
}

export default HomePage