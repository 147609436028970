import React, { useContext, useEffect, useState } from 'react'
import AppContext from '../AppContext';
import AppendScript from '../components/AppendScript';
import Alljson from './AllDetails.json';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import { scrollBottomToTop } from '../components/Function';
import SubscribeSection from '../components/SubscribeSection';
import useGAEventTracker from '../components/useGAEventTracker';

function SpecialCut() {
	const history = useHistory();
	const { dispatchUrlChange } = useContext(AppContext);

	const SpecialCutPage = Alljson?.data?.specialCut;
    const GeneralDetails = Alljson?.data?.SiteDetails;

	useEffect(() => {
		dispatchUrlChange('CHNAGE_URLNAME', { data: ['special-cut'] });
		document.body.classList.add('special-cut-page');
		scrollBottomToTop();
	}, [])

	function chkBlank(text) {
		if (text != "") {
			return text;
		} else {
			return "";
		}
	}

    const GAEventsTracker = useGAEventTracker("Links");

	const ContentClickHandler = (e) => {
        const targetLink = e.target.closest('a');
        if (!targetLink) return;
        e.preventDefault();
        if (targetLink.href) {
            const targetBlank = targetLink.target;

            if(targetBlank == "_blank"){
                window.open(targetLink.href, '_blank');
            }else{
                const link = targetLink.href.split("/").pop();
                if (link) {
                    history.push(`/${link}`);
                }
            }
        }
    }

	const SliderSetting = {
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        autoplay: true,
        autoplaySpeed: 2000,
		arrows: false,
		dots: true,
		fade: true,
		className: 'the-optica-slider'
    };

	const [showMore, setShowMore] = useState(false);

	const showMoreBtn = () => {
		setShowMore(!showMore);
    }

	return (
		<>
			<div className="wrapper">
				{
					SpecialCutPage?.pageContent?.length > 0 ?
						<div className="content-main">
							{
								SpecialCutPage.pageContent.map(function (ele, i) {
									if (ele?.acf_fc_layout == "two_section_special_cut") {
										return (
											<>
												<div className="section-white the-optica-section" key={`sec-${i}`}>
													<div className="container container-big">
														<div className="list-main">
															<div className={`list left-content MainDiv TabHeading ${showMore ? 'active-tab' : ''}`}>
																<div className="tit-section slide-top">
																	<h1 className="tit"><span className="sub-tit">{chkBlank(ele?.left_section_title)}</span>{chkBlank(ele?.left_section_sub_title)}</h1>
																	<div className="desc" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(ele?.description) }}></div>
																	<div className="more-btn"><button className="btn btn-pink btn-outline text-white2 more TabHeadingMore" onClick={showMoreBtn}>Read More</button><button className="btn btn-pink btn-outline text-white2 less TabHeadingMore" onClick={showMoreBtn}>Read Less</button></div>
																</div>
															</div>
															<div className="list right-content slide-top">
																<div className="video"><button type="button" className="video-btn" data-bs-toggle="modal" data-bs-target="#TheOpticaSeries"><svg className="feather" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="m383 746 267-170-267-170v340Zm97 230q-82 0-155-31.5t-127.5-86Q143 804 111.5 731T80 576q0-83 31.5-156t86-127Q252 239 325 207.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 82-31.5 155T763 858.5q-54 54.5-127 86T480 976Z" /></svg>Watch Video</button>
																	{
																		chkBlank(ele?.image) ?
																			<img className="video-img" src={ele.image} alt={chkBlank(ele?.video_url)} title="" />
																			: ''
																	}
																</div>
															</div>
														</div>
													</div>
												</div>
												{/* <!-- Start The OPTICA Series Modal --> */}
												<div className="modal fade" id="TheOpticaSeries" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="TheOpticaSeriesLabel" aria-hidden="true">
													<div className="modal-dialog modal-dialog-centered the-optica-series-video-modal">
														<div className="modal-content">
															<div className="modal-header">
																<h1 className="modal-title" id="TheOpticaSeriesLabel">Introducing The OPTICA Series</h1>
																<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18" /><line x1="6" y1="6" x2="18" y2="18" /></svg></button>
															</div>
															<div className="modal-body pd-0">
																<iframe id="TheOpticaSeriesVideo" src={ele?.video_url} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" width="100%" height="500" frameBorder="" ></iframe>
															</div>
														</div>
													</div>
												</div>
												{/* <!-- End The OPTICA Series Modal --> */}
											</>
										)
									} else if (ele?.acf_fc_layout == "optica_series_section_special_cut") {
										return (
											<div className="section-white ftco-section pdt-0" key={`sec-${i}`}>
												<div className="container container-big">
													<div className="section-title2 pdb-0 text-center slide-top">
														<h3 className="title text-white"><span className="sub-title text-sky-blue">{chkBlank(ele?.main_title)}</span>{chkBlank(ele?.sub_title)}</h3>
													</div>
													{
														ele?.section_list?.length > 0 ?
															<>
																<ul className="ftco-list">
																	{
																		ele?.section_list.map(function (sec, s) {
																			return (
																				<li className="list slide-top" key={`list-${s}`}>
																					<div className="icon-main">
																						<div className="icon-bg1 slide-bottom2"></div>
																						<div className="icon-bg2 slide-top2">
																							{
																								chkBlank(sec?.image) ?
																									<img className="icon-img" src={sec.image} alt="" title="" />
																									: ''
																							}
																						</div>
																					</div>
																					<div className="section-title2 pdb-0 text-center"><h4 className="title text-white">{chkBlank(sec?.title)}</h4></div>
																					{
																						sec?.feature_list?.length > 0 ?
																							<>
																								<ul className="list02">
																									{
																										sec.feature_list.map(function (fea, f) {
																											return (
																												<li key={`fea-${f}`}><span>{fea?.features}</span></li>
																											)
																										})
																									}
																								</ul>
																							</>
																							: ''
																					}
																				</li>
																			)
																		})
																	}
																</ul>
															</>
															: ''
													}
												</div>
											</div>
										)
									} else if (ele?.acf_fc_layout == "left_side_slider_section") {
										return (
											<>
												<div className="section-white the-optica-section2 stelo-section" key={`sec-${i}`}>
													<div className="container">
														<div className="list-main">
															<div className="list left-content slide-top">
																<Slider {...SliderSetting}>
																	{
																		ele?.slider_image?.length > 0 ?
																			ele.slider_image.map(function (img, im) {
																				return (
																					<div className="slider-item" key={`lsDiv-${im}`}>
																						{
																							chkBlank(img?.image) ?
																								<img src={img.image} alt="" title="" />
																								: ''
																						}
																					</div>
																				)
																			})
																		: ''
																	}
																</Slider>
															</div>
															<div className="list right-content">
																<div className="section-title slide-top">
																	<h2 className="title text-white"><span className="sub-title text-sky-blue2">{chkBlank(ele?.section_main_title)}</span> {chkBlank(ele?.section_sub_title)}</h2>
																	<div className="description text-white" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(ele?.section_description) }}></div>
																</div>
																<div className="more-btn"><button type="button" className="btn btn-blue2" data-bs-toggle="modal" data-bs-target="#TheSteloStory">The Stelo Story</button></div>
															</div>
														</div>
													</div>
												</div>
												{/* <!-- Start The Optica Stelo Modal --> */}
												<div className="modal fade" id="TheSteloStory" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="TheSteloStoryLabel" aria-hidden="true">
													<div className="modal-dialog modal-dialog-centered the-optica-modal the-stelo-modal">
														<div className="modal-content">
															<div className="modal-header">
																<h1 className="modal-title" id="TheSteloStoryLabel">{/* <!-- The Optica Stelo --> */}</h1>
																<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18" /><line x1="6" y1="6" x2="18" y2="18" /></svg></button>
															</div>
															<div className="modal-body">
																<div className="modal-scroll">
																	<div className="section-white the-optica-section-modal">
																		<div className="list-main">
																			<div className="list left-content">
																				<Slider {...SliderSetting}>
																					{
																						ele?.slider_image?.length > 0 ?
																							ele.slider_image.map(function (img, im) {
																								return (
																									<div className="slider-item" key={`lsDiv-${im}`}>
																										{
																											chkBlank(img?.image) ?
																												<img src={img.image} alt="" title="" />
																												: ''
																										}
																									</div>
																								)
																							})
																						: ''
																					}
																				</Slider>
																			</div>
																			<div className="list right-content">
																				<div className="section-title">
																					<h2 className="title text-white"><span className="sub-title text-sky-blue2">{chkBlank(ele?.section_main_title)}</span> {chkBlank(ele?.section_sub_title)}</h2>
																					<div className="description text-white pdb-0" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(ele?.section_modal_description) }}></div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												{/* <!-- End The Optica Stelo Modal --> */}
											</>
										)
									} else if (ele?.acf_fc_layout == "right_side_slider_section") {
										return (
											<>
												<div className="section-white the-optica-section2 rozo-section" key={`sec-${i}`}>
													<div className="container">
														<div className="list-main">
															<div className="list left-content slide-top">
																<Slider {...SliderSetting}>
																	{
																		ele?.slider_image?.length > 0 ?
																			ele.slider_image.map(function (img, im) {
																				return (
																					<div className="slider-item" key={`rsDiv-${im}`}>
																						{
																							chkBlank(img?.image) ?
																								<img src={img.image} alt="" title="" />
																								: ''
																						}
																					</div>
																				)
																			})
																			: ''
																	}
																</Slider>
															</div>
															<div className="list right-content">
																<div className="section-title slide-top">
																	<h2 className="title text-white"><span className="sub-title text-pink">{chkBlank(ele?.section_main_title)}</span> {chkBlank(ele?.section_sub_title)}</h2>
																	<div className="description text-white" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(ele?.section_description) }}></div>
																</div>
																<div className="more-btn"><button type="button" className="btn btn-red2" data-bs-toggle="modal" data-bs-target="#TheRozoStory">The Rozo Story</button></div>
															</div>
														</div>
													</div>
												</div>
												{/* <!-- Start The Optica Rozo Modal --> */}
												<div className="modal fade" id="TheRozoStory" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="TheRozoStoryLabel" aria-hidden="true">
													<div className="modal-dialog modal-dialog-centered the-optica-modal the-rozo-modal">
														<div className="modal-content">
															<div className="modal-header">
																<h1 className="modal-title" id="TheRozoStoryLabel">{/* <!-- The Optica Rozo --> */}</h1>
																<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18" /><line x1="6" y1="6" x2="18" y2="18" /></svg></button>
															</div>
															<div className="modal-body">
																<div className="modal-scroll">
																	<div className="section-white the-optica-section-modal">
																		<div className="list-main">
																			<div className="list left-content">
																				<Slider {...SliderSetting}>
																					{
																						ele?.slider_image?.length > 0 ?
																							ele.slider_image.map(function (img, im) {
																								return (
																									<div className="slider-item" key={`rsmDiv-${im}`}>
																										{
																											chkBlank(img?.image) ?
																												<img src={img.image} alt="" title="" />
																												: ''
																										}
																									</div>
																								)
																							})
																							: ''
																					}
																				</Slider>
																			</div>
																			<div className="list right-content">
																				<div className="section-title">
																					<h2 className="title text-white"><span className="sub-title text-pink">{chkBlank(ele?.section_main_title)}</span> {chkBlank(ele?.section_sub_title)}</h2>
																					<div className="description text-white pdb-0" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(ele?.section_modal_description) }}></div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												{/* <!-- End The Optica Rozo Modal --> */}
											</>
										)
									} else if (ele?.acf_fc_layout == "left_side_slider_section_special_cut") {
										return (
											<>
												<div className="section-white the-optica-section2 floro-section" key={`sec-${i}`}>
													<div className="container">
														<div className="list-main">
															<div className="list left-content slide-top">
																<Slider {...SliderSetting}>
																	{
																		ele?.slider_image?.length > 0 ?
																			ele.slider_image.map(function (img, im) {
																				return (
																					<div className="slider-item" key={`lssDiv-${im}`}>
																						{
																							chkBlank(img?.image) ?
																								<img src={img.image} alt="" title="" />
																								: ''
																						}
																					</div>
																				)
																			})
																		: ''
																	}
																</Slider>
															</div>
															<div className="list right-content">
																<div className="section-title slide-top">
																	<h2 className="title text-white"><span className="sub-title text-pink">{chkBlank(ele?.section_main_title)}</span> {chkBlank(ele?.section_sub_title)}</h2>
																	<div className="description text-white" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(ele?.section_description) }}></div>
																</div>
																<div className="more-btn"><button type="button" className="btn btn-red2" data-bs-toggle="modal" data-bs-target="#TheFloroStory">The Floro Story</button></div>
															</div>
														</div>
													</div>
												</div>
												{/* <!-- Start The Optica Floro Modal --> */}
												<div className="modal fade" id="TheFloroStory" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="TheFloroStoryLabel" aria-hidden="true">
													<div className="modal-dialog modal-dialog-centered the-optica-modal the-floro-modal">
														<div className="modal-content">
															<div className="modal-header">
																<h1 className="modal-title" id="TheFloroStoryLabel">{/* <!-- The Optica Floro --> */}</h1>
																<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18" /><line x1="6" y1="6" x2="18" y2="18" /></svg></button>
															</div>
															<div className="modal-body">
																<div className="modal-scroll">
																	<div className="section-white the-optica-section-modal">
																		<div className="list-main">
																			<div className="list left-content">
																				<Slider {...SliderSetting}>
																					{
																						ele?.slider_image?.length > 0 ?
																							ele.slider_image.map(function (img, im) {
																								return (
																									<div className="slider-item" key={`lssmDiv-${im}`}>
																										{
																											chkBlank(img?.image) ?
																												<img src={img.image} alt="" title="" />
																												: ''
																										}
																									</div>
																								)
																							})
																						: ''
																					}
																				</Slider>
																			</div>
																			<div className="list right-content">
																				<div className="section-title">
																					<h2 className="title text-white"><span className="sub-title text-pink">{chkBlank(ele?.section_main_title)}</span> {chkBlank(ele?.section_sub_title)}</h2>
																					<div className="description text-white pdb-0" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(ele?.section_modal_description) }}></div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												{/* <!-- End The Optica Floro Modal --> */}
											</>
										)
									} else if (ele?.acf_fc_layout == "title_content_section") {
										return (
											<div className="section-white innovation-collaboration-section" key={`sec-${i}`}>
												<div className="container">
													<h3 className="title-section slide-top" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(ele?.title) }}></h3>
													<div className="slide-top" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(ele?.content) }}></div>
												</div>
											</div>
										)
									} else if (ele?.acf_fc_layout == "protection_section_special_cut") {
										return (
											<div className="section-white protection-guarantee-section mgb" key={`sec-${i}`}>
												<div className="container container-big">
													<div className="list-main">
														<div className="list left-content">
															<h3 className="title-section slide-top">
																{
																	chkBlank(ele?.image) ?
																		<img className="icon-img" src={ele.image} alt="" title="" />
																		: ''
																}
																{chkBlank(ele?.title)}
															</h3>
														</div>
														<div className="list right-content slide-top" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(ele?.description) }}></div>
													</div>
													<div className="section-title2 pdb-0 text-center slide-top">
														<h4 className="title" onClick={ContentClickHandler} dangerouslySetInnerHTML={{ __html: chkBlank(ele?.center_text) }}></h4>
													</div>
													{
														ele?.feature_list?.length > 0 ?
															<>
																<ul className="box-list-main">
																	{
																		ele.feature_list.map(function (fe, f) {
																			return (
																				<li className="slide-top" key={`psli-${f}`}>
																					<div className="box-inn">
																						<div className="list2 left-list">{chkBlank(fe?.title)}</div>
																						<div className="list2 right-list">
																							{
																								chkBlank(fe?.image) ?
																									<img className="icon-img" src={fe.image} alt="" title="" />
																									: ''
																							}
																						</div>
																					</div>
																				</li>
																			)
																		})
																	}
																</ul>
															</>
															: ''
													}
													<p className="mgb-0 text-center terms">{chkBlank(ele?.bottom_text)}</p>
												</div>
											</div>
										)
									}
								})
							}

							<SubscribeSection />
							<div id="parallax" className="section-white follow-our-journey-section mgb">
								<div className="container">
									<div className="caption">
										<div className="caption-inn slide-top">
											<h2 className="title-section text-white">Follow our journey</h2>
											{
												chkBlank(GeneralDetails?.instagram_link) ?
													<a className="btn btn-white" target="_blank" href={GeneralDetails?.instagram_link} onClick={e => GAEventsTracker("Social Media",GeneralDetails.instagram_link)}>{chkBlank(GeneralDetails.instagram_button_label)}</a>
													: ''
											}
										</div>
									</div>
								</div>
							</div>
						</div>
						: ''
				}
				{/* <!--/.content-main--> */}
				<AppendScript />
			</div>
		</>
	)
}

export default SpecialCut