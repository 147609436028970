import { animateScroll as scroll } from 'react-scroll';

const scrollBottomToTop = () => {
    scroll.scrollToTop({
        duration: 100,
        smooth: 'easeInOutQuart',
        delay: 0,
        offset: -50,
    });
}

export {
    scrollBottomToTop
}